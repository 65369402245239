import axios from "axios";
import { API } from "../../Backend";
import {
    ALL_CHALLAN_FAIL,
    ALL_CHALLAN_REQUEST,
    ALL_CHALLAN_SUCCESS,
    CREATE_CHALLAN_FAIL,
    CREATE_CHALLAN_REQUEST,
    CREATE_CHALLAN_SUCCESS,
    CHALLAN_DETAILS_REQUEST,
    CHALLAN_DETAILS_SUCCESS,
    CHALLAN_DETAILS_FAIL,
    DELETE_CHALLAN_FAIL,
    DELETE_CHALLAN_REQUEST,
    DELETE_CHALLAN_SUCCESS,
    UPDATE_CHALLAN_FAIL,
    UPDATE_CHALLAN_REQUEST,
    UPDATE_CHALLAN_SUCCESS,
    CHALLAN_PAGE_UPDATE,
    CLEAR_ERRORS
} from "../constants/challanConstant"

export const getChallans = (page, challansPerPage) => async(dispatch) => {
    try {
        dispatch({ type: ALL_CHALLAN_REQUEST })

        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }
        
        const { data } = await axios.get(`${API}/challan/get?page=${page}&limit=${challansPerPage}`, config)
        dispatch({type: ALL_CHALLAN_SUCCESS, payload: data})

    } catch (error) {
        dispatch({
            type: ALL_CHALLAN_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getChallanDetails = (id) => async(dispatch) => {
    try {
        dispatch({ type: CHALLAN_DETAILS_REQUEST })
        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }
        
        const {data} = await axios.get(`${API}/challan/getSingle/${id}`, config)
        dispatch({type: CHALLAN_DETAILS_SUCCESS, payload: data.challan})
        return data
    } catch (error) {
        dispatch({
            type: CHALLAN_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const createChallan = (details) => async(dispatch) => {
    try {
        dispatch({type: CREATE_CHALLAN_REQUEST})
        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }
        const {data} = await axios.post(`${API}/challan/post`, details, config)
        dispatch({type: CREATE_CHALLAN_SUCCESS, payload: data})
        return data

    } catch (error) {
        dispatch({
            type: CREATE_CHALLAN_FAIL,
            payload: error.response.data.message
        })
        return error.response.data
    }
}


export const deleteChallan = (id) => async(dispatch) => {
    try {
        dispatch({type: DELETE_CHALLAN_REQUEST})
        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }
        const { data } = await axios.delete(`${API}/challan/delete/${id}`, config)
        dispatch({type: DELETE_CHALLAN_SUCCESS, payload: id})
        return data

    } catch (error) {
        dispatch({
            type: DELETE_CHALLAN_FAIL,
            payload: error.response.data.message
        })
        return error.response.data
    }
}

export const updateChallan = (id, details) => async(dispatch) => {
    try {
        dispatch({type: UPDATE_CHALLAN_REQUEST})
        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }
        const {data} = await axios.put(`${API}/challan/put/${id}`, details, config)
        dispatch({type: UPDATE_CHALLAN_SUCCESS, payload: data})
        return data

    } catch (error) {
        dispatch({
            type: UPDATE_CHALLAN_FAIL,
            payload: error.response.data.message
        })
        return error.response.data
    }
}

export const updateChallanPage = (page) => async(dispatch) => {
    dispatch({type: CHALLAN_PAGE_UPDATE, payload: page})
}


// Used for cleaning errors
export const clearErrors = () => async(dispatch) => {
    dispatch({type: CLEAR_ERRORS})
}