import axios from "axios";
import { API } from "../../Backend";
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOAD_USER_FAIL,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOGOUT_USER_FAIL,
    LOGOUT_USER_REQUEST,
    LOGOUT_USER_SUCCESS,
    CLEAR_ERRORS
} from "../constants/userConstant"

export const login = (phone, password) => async(dispatch) => {
    try {
        dispatch({type: LOGIN_REQUEST})
        const config = {
            headers: {
                "Content-type": 'application/json',
            },
            withCredentials: true
        }
        const {data} = await axios.post(`${API}/auth/login`, {phone, password}, config)
        dispatch({type: LOGIN_SUCCESS, payload: data.user})
        return data

    } catch (error) {
        dispatch({
            type: LOGIN_FAIL,
            payload: error.response.data.message
        })
        return error.response.data
    }
}

export const loadUser = () => async(dispatch) => {
    try {
        dispatch({type: LOAD_USER_REQUEST})
        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }
        const {data} = await axios.get(`${API}/auth/me`, config)
        dispatch({type: LOAD_USER_SUCCESS, payload: data.user})
        localStorage.setItem("user", JSON.stringify(data.user))
        return data
    } catch (error) {
        dispatch({
            type: LOAD_USER_FAIL,
            payload: error.response.data.message
        })
        return error.response.data
    }
}

export const logout = () => async(dispatch) => {
    try {
        dispatch({type: LOGOUT_USER_REQUEST})
        const config = {
            headers: {
                "Content-type": 'application/json',
            },
            withCredentials: true
        }
        const {data} = await axios.get(`${API}/auth/logout`, config)
        localStorage.clear()
        dispatch({type: LOGOUT_USER_SUCCESS, payload: data.user})
        return data

    } catch (error) {
        dispatch({
            type: LOGOUT_USER_FAIL,
            payload: error.response.data.message
        })
    }
}


// Used for cleaning errors
export const clearErrors = () => async(dispatch) => {
    dispatch({type: CLEAR_ERRORS})
}