export const ALL_BILL_REQUEST = "ALL_BILL_REQUEST"
export const ALL_BILL_SUCCESS = "ALL_BILL_SUCCESS"
export const ALL_BILL_FAIL = "ALL_BILL_FAIL"
export const BILL_DETAILS_REQUEST = "BILL_DETAILS_REQUEST"
export const BILL_DETAILS_SUCCESS = "BILL_DETAILS_SUCCESS"
export const BILL_DETAILS_FAIL = "BILL_DETAILS_FAIL"
export const CREATE_BILL_REQUEST = "CREATE_BILL_REQUEST"
export const CREATE_BILL_SUCCESS = "CREATE_BILL_SUCCESS"
export const CREATE_BILL_FAIL = "CREATE_BILL_FAIL"
export const DELETE_BILL_REQUEST = "DELETE_BILL_REQUEST"
export const DELETE_BILL_SUCCESS = "DELETE_BILL_SUCCESS"
export const DELETE_BILL_FAIL = "DELETE_BILL_FAIL"
export const UPDATE_BILL_REQUEST = "UPDATE_BILL_REQUEST"
export const UPDATE_BILL_SUCCESS = "UPDATE_BILL_SUCCESS"
export const UPDATE_BILL_FAIL = "UPDATE_BILL_FAIL"
export const CLEAR_ERRORS = "CLEAR_ERRORS"