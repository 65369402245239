import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getPriceListDetails } from '../../redux/actions/priceListAction'
import Loader from '../../utility/Loader'
import ItemsHandler from '../../components/PriceList/ItemsHandler'

const NewPriceList = () => {

    const { id } = useParams()
    const dispatch = useDispatch()
    const {priceLists} = useSelector(state=>state.priceLists)

    const [priceList, setPriceList] = useState()
    const [loading, setLoading] = useState(false)

    let duplicate = (new URLSearchParams(window.location.search)).get("duplicate")
    let duplicateId = (new URLSearchParams(window.location.search)).get("duplicateId")

    useEffect(() => {
        if (duplicate && duplicateId) {
          duplicateHandler()
        }
      }, [])

    const duplicateHandler = () => {
    setLoading(true)
    const isPriceListExist = priceLists.find((i) => i._id === id)
    if (isPriceListExist) {
        setStatesForDuplication(isPriceListExist)
    } else {
        dispatch(getPriceListDetails(duplicateId)).then((res) => {
        if (res.success) {
            setStatesForDuplication(res.priceList)
        }
        })
    }
    }

    const setStatesForDuplication = (details) => {
        setPriceList(details)
        setLoading(false)
      }

  return loading ? <Loader/> : (<ItemsHandler priceList={priceList} actionType={"new"} />) 
}

export default NewPriceList

