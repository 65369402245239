import axios from "axios";
import { API } from "../../Backend";
import {
    ALL_BILL_FAIL,
    ALL_BILL_REQUEST,
    ALL_BILL_SUCCESS,
    CREATE_BILL_FAIL,
    CREATE_BILL_REQUEST,
    CREATE_BILL_SUCCESS,
    BILL_DETAILS_REQUEST,
    BILL_DETAILS_SUCCESS,
    BILL_DETAILS_FAIL,
    DELETE_BILL_FAIL,
    DELETE_BILL_REQUEST,
    DELETE_BILL_SUCCESS,
    UPDATE_BILL_FAIL,
    UPDATE_BILL_REQUEST,
    UPDATE_BILL_SUCCESS,
    CLEAR_ERRORS
} from "../constants/billConstant"

export const getBills = () => async(dispatch) => {
    try {
        dispatch({ type: ALL_BILL_REQUEST })

        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }
        
        const { data } = await axios.get(`${API}/bill/get`, config)
        dispatch({type: ALL_BILL_SUCCESS, payload: data})

    } catch (error) {
        dispatch({
            type: ALL_BILL_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getBillDetails = (id) => async(dispatch) => {
    try {
        dispatch({ type: BILL_DETAILS_REQUEST })
        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }
        
        const {data} = await axios.get(`${API}/bill/getSingle/${id}`, config)
        dispatch({type: BILL_DETAILS_SUCCESS, payload: data.bill})
        return data
    } catch (error) {
        dispatch({
            type: BILL_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const createBill = (details) => async(dispatch) => {
    try {
        dispatch({type: CREATE_BILL_REQUEST})
        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }
        const {data} = await axios.post(`${API}/bill/post`, details, config)
        dispatch({type: CREATE_BILL_SUCCESS, payload: data})
        return data

    } catch (error) {
        dispatch({
            type: CREATE_BILL_FAIL,
            payload: error.response.data.message
        })
        return error.response.data
    }
}


export const deleteBill = (id) => async(dispatch) => {
    try {
        dispatch({type: DELETE_BILL_REQUEST})
        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }
        const { data } = await axios.delete(`${API}/bill/delete/${id}`, config)
        dispatch({type: DELETE_BILL_SUCCESS, payload: id})
        return data

    } catch (error) {
        dispatch({
            type: DELETE_BILL_FAIL,
            payload: error.response.data.message
        })
        return error.response.data
    }
}

export const updateBill = (id, details) => async(dispatch) => {
    try {
        dispatch({type: UPDATE_BILL_REQUEST})
        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }
        const {data} = await axios.put(`${API}/bill/put/${id}`, details, config)
        dispatch({type: UPDATE_BILL_SUCCESS, payload: data})
        return data

    } catch (error) {
        dispatch({
            type: UPDATE_BILL_FAIL,
            payload: error.response.data.message
        })
        return error.response.data
    }
}


// Used for cleaning errors
export const clearErrors = () => async(dispatch) => {
    dispatch({type: CLEAR_ERRORS})
}