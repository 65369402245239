import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import Modal from "../../components/utility/Modal"
import Toaster from "../../components/utility/Toaster"
import { deletePriceList } from '../../redux/actions/priceListAction'

const DeletePriceList = ({id}) => {

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

    // toaster states
    const [toaster, setToaster] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [toasterType, setToasterType] = useState("")

  const handelSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    dispatch(deletePriceList(id))
    .then(res=>{
      setLoading(false)
      if (!res.success) {
        handleToaster(res.message, "error")
      } else {
        handleToaster("Deleted successfully", "success")
      }
      closeModal()
    })
  }

  const closeModal = () => {
    document.getElementById("closeDeletePriceListModal").click()
  }

  const handleToaster = (message, icon) => {
    setToaster(true)
    setToasterMessage(message)
    setToasterType(icon)
  }

  return (
    <section>
        <Modal targetName={"deletePriceList"}>
            <div className='p-lg-5 p-1 text-center'>
                <h1>Delete PriceList</h1>
                <div className="row">
                    <div className="col-12 col-lg-6 mt-5">
                        <button className="btn btn-primary shadow w-100" onClick={closeModal}>Cancel</button>
                    </div>
                    <div className="col-12 col-lg-6 mt-5">
                        <button className="btn btn-danger shadow w-100" onClick={handelSubmit}>{loading ? <div className="spinner-border button-spinner" role="status"><span className="visually-hidden">Loading...</span></div> : "Delete"}</button>
                    </div>
                </div>
            </div>
            <button className='d-none' data-bs-dismiss="modal" id="closeDeletePriceListModal">Close modal</button>
        </Modal>
        <Toaster text={toasterMessage} toasterType={toasterType} toaster={toaster} setToaster={setToaster} />
    </section>
  )
}

export default DeletePriceList