import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';

const Navbar = () => {
  const location = useLocation();
  const isPriceListPage = location.pathname.startsWith('/priceList');
  const isBillPage = location.pathname.startsWith('/bill');
  const isPerformaInvoicePage = location.pathname.startsWith('/performaInvoice');
  const isChallanPage = !isPriceListPage && !isBillPage && !isPerformaInvoicePage

  return (
    <nav className='navbar navbar-light position-lg-sticky top-lg-0 overlap-10 flex-none bg-primary border-bottom px-0 py-3 d-print-none'>
      <div className="container d-flex align-items-center justify-content-between gap-2">
        <Link to='/' className='text-white h6 fw-bold'>Omtech Auto</Link>
        <div>
          {isPriceListPage && <Link to={"/priceList/new"} className="p-2 px-4 bg-new-warning rounded text-white text-l fw-semibold">+ New Price List</Link> }
          {isChallanPage && <Link to={"/challan/new"} className="p-2 px-4 bg-new-warning rounded text-white text-l fw-semibold">+ New Challan</Link> }
          {isBillPage && <Link to={"/bill/new"} className="p-2 px-4 bg-new-warning rounded text-white text-l fw-semibold">+ New Bill</Link> }
          {isPerformaInvoicePage && <Link to={"/performaInvoice/new"} className="p-2 px-4 bg-new-warning rounded text-white text-l fw-semibold">+ New PI</Link> }
          <span className="p-2 px-3 bg-new-secondary rounded text-white text-l fw-semibold ms-2 cursor-pointer" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
            <i className="bi bi-list"></i>
          </span>
        </div>
      </div>
      <Sidebar />
    </nav>
  );
};

export default Navbar;
