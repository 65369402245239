import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./utility/Dashboard"
import Homepage from "./pages/Homepage"
import NotFound from "./utility/NotFound"
import PrivateRoute from "./utility/PrivateRoute"
import Login from './pages/auth/Login'
import NotAllowed from './utility/NotAllowed'
import Challans from "./pages/challans/Challans";
import NewChallan from "./pages/challans/NewChallan";
import EditChallan from "./pages/challans/EditChallan";
import PrintChallan from "./pages/challans/PrintChallan";
import FeatureTesting from "./pages/FeatureTesting";
import PriceList from "./pages/priceList/PriceList";
import EditPriceList from "./pages/priceList/EditPriceList";
import ViewPriceList from "./pages/priceList/ViewPriceList";
import NewPriceList from "./pages/priceList/NewPriceList";
import PriceListLoadedRoute from "./utility/PriceListLoadedRoute";
import EditBill from "./pages/bills/EditBill";
import NewBill from "./pages/bills/NewBill";
import Bills from "./pages/bills/Bills";
import PrintBill from "./pages/bills/PrintBill";
import BillsLoader from "./utility/BillsLoader";
import PerformaInvoices from "./pages/performaInvoices/PerformaInvoices";
import PerformaInvoicesLoader from "./utility/PerformaInvoiceLoader";
import NewPerformaInvoice from "./pages/performaInvoices/NewPerformaInvoice";
import EditPerformaInvoice from "./pages/performaInvoices/EditPerformaInvoice";
import PrintPerformaInvoice from "./pages/performaInvoices/PrintPerformaInvoice";
import Analytics from "./pages/analytics/Analytics";
import TopItemsSold from "./pages/analytics/TopItemsSold";
import TotalSales from "./pages/analytics/TotalSales";
import TopCustomers from "./pages/analytics/TopCustomers";
import TaxSales from "./pages/analytics/TaxSales";
import Accounts from "./pages/analytics/Accounts";

function App() {

  return (
    <Router>
      <Routes>
        <Route element={<PrivateRoute />}>
          {/* Routes with dashbaord layout */}
          <Route element={<Dashboard />}>
            <Route exact path='/' element={<Homepage />} />
            <Route exact path='/challans' element={<Challans />} />
            <Route exact path='/bills' element={<Bills />} />
            <Route exact path='/performaInvoices' element={<PerformaInvoices />} />
            {/* Load Price List Before Visiting These Pages */}
            <Route element={<PriceListLoadedRoute />}>
              <Route exact path='/challan/new' element={<NewChallan />} />
              <Route exact path='/challan/:id' element={<EditChallan />} />
              <Route element={<BillsLoader />}>
                <Route element={<PerformaInvoicesLoader />}>
                  <Route exact path='/bill/new' element={<NewBill />} />
                  <Route exact path='/bill/:id' element={<EditBill />} />
                  <Route exact path='/performaInvoice/new' element={<NewPerformaInvoice />} />
                  <Route exact path='/performaInvoice/:id' element={<EditPerformaInvoice />} />
                </Route>
              </Route>
            </Route>
            {/* Price List */}
            <Route exact path='/priceList' element={<PriceList />} />
            <Route exact path='/priceList/new' element={<NewPriceList />} />
            <Route exact path='/priceList/:id' element={<EditPriceList />} />
            {/* Analytics */}
            <Route exact path='/analytics' element={<Analytics />} />

          </Route>
          {/* Routes without Dashboard Layout */}
          <Route exact path='/priceList/view/:id' element={<ViewPriceList />} />
          <Route exact path='/challan/print/:id' element={<PrintChallan />} />
          <Route exact path='/bill/print/:id' element={<PrintBill />} />
          <Route exact path='/performaInvoice/print/:id' element={<PrintPerformaInvoice />} />
          {/* Analytics */}
          <Route exact path='/analytics/topItemsSold' element={<TopItemsSold />} />
          <Route exact path='/analytics/topCustomers' element={<TopCustomers />} />
          <Route exact path='/analytics/totalSales' element={<TotalSales />} />
          <Route exact path='/analytics/taxSales' element={<TaxSales />} />
          <Route exact path='/analytics/accounts' element={<Accounts />} />

        </Route>
        {/* Non Private Routes */}
        <Route exact path='/login' element={<Login />} />
        <Route exact path='/notallowed' element={<NotAllowed />} />
        <Route exact path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
