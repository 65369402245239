import React, { useEffect, useState } from 'react';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createPriceList, updatePriceList } from '../../redux/actions/priceListAction';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loading from '../utility/Loading';

const ItemsHandler = ({ priceList, actionType }) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [name, setName] = useState(priceList.name)
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)

  const [updatedPriceList, setUpdatedPriceList] = useState(priceList);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);


  const handleCategoryNameChange = (categoryIndex, newName) => {
    setUpdatedPriceList((prevPriceList) => {
      const updatedList = [...prevPriceList.details];
      updatedList[categoryIndex].categoryName = newName;
      return { ...prevPriceList, details: updatedList };
    });
  };

  const handleCategoryItemChange = (categoryIndex, itemIndex, field, newValue) => {
    setUpdatedPriceList((prevPriceList) => {
      const updatedList = [...prevPriceList.details];
      updatedList[categoryIndex].categoryItems[itemIndex][field] = newValue;
      return { ...prevPriceList, details: updatedList };
    });
  };

  const addNewCategory = () => {
    setUpdatedPriceList((prevPriceList) => {
      const updatedList = [...prevPriceList.details, { categoryName: '', categoryItems: [] }];
      return { ...prevPriceList, details: updatedList };
    });
    document.getElementById("bottom-div").scrollIntoView({ behavior: 'smooth' })
  };

  const addNewItem = (categoryIndex) => {
    setUpdatedPriceList((prevPriceList) => {
      const updatedList = [...prevPriceList.details];
      updatedList[categoryIndex].categoryItems.push({ name: '', position: '', price: '' });
      return { ...prevPriceList, details: updatedList };
    });
  };

  const deleteItem = (categoryIndex, itemIndex) => {
    setUpdatedPriceList((prevPriceList) => {
      const updatedList = [...prevPriceList.details];
      updatedList[categoryIndex].categoryItems.splice(itemIndex, 1);
      return { ...prevPriceList, details: updatedList };
    });
  };

  const deleteCategory = (categoryIndex) => {
    setSelectedCategory(categoryIndex);
    setDeleteConfirmationOpen(true);
  };

  const confirmDeleteCategory = () => {
    setUpdatedPriceList((prevPriceList) => {
      const updatedList = [...prevPriceList.details];
      updatedList.splice(selectedCategory, 1);
      return { ...prevPriceList, details: updatedList };
    });
    setDeleteConfirmationOpen(false);
    setSelectedCategory(null);
  };

  const cancelDeleteCategory = () => {
    setDeleteConfirmationOpen(false);
    setSelectedCategory(null);
  };

  const handleSubmit = () => {
    setLoading(true)
    const data = {
      name: name,
      details: updatedPriceList.details
    }
    dispatch(actionType === "new" ? createPriceList(data) : updatePriceList(priceList._id, data))
    .then(res => {
      if (res.success) {
        navigate('/priceList')
      } else {
        setError(res.message)
      }
      setLoading(false)
    })
  }
  
  return (
    <div>
      <TextField size='large' className='w-100' label="Price List Name" variant="standard" value={name} onChange={(e)=>setName(e.target.value)}/>
      {updatedPriceList.details.map((category, categoryIndex) => (
            <div className='mt-5 d-flex align-items-center flex-column text-uppercase' key={categoryIndex}>
              <div className='bg-new-danger mt-5 py-3 text-white text-center w-100'><input type='text' className='price-list-input text-center text-white fw-semibold' placeholder='Brand Name' value={category.categoryName} onChange={(e) => handleCategoryNameChange(categoryIndex, e.target.value)} /></div>
              {category.categoryItems && category.categoryItems.map((item, itemIndex)=>(
                  <div className={`row w-100 ${itemIndex%2===0 ? 'bg-gray-200' : 'bg-gray-300'}`} key={itemIndex}>
                      <div className="px-0 col-8 col-md-8 col-lg-8">
                        <input type="text" className='price-list-input' placeholder='Name' value={item.name} onChange={(e) =>
                            handleCategoryItemChange(
                              categoryIndex,
                              itemIndex,
                              'name',
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="px-0 col-2 col-md-1 col-lg-1">
                        <input type="text" className='price-list-input' placeholder='Pos' value={item.position} onChange={(e) =>
                            handleCategoryItemChange(
                              categoryIndex,
                              itemIndex,
                              'position',
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="px-0 col-2 col-md-2 col-lg-2">
                        <input type="number" className='price-list-input' placeholder='Rs' value={item.price} onChange={(e) =>
                            handleCategoryItemChange(
                              categoryIndex,
                              itemIndex,
                              'price',
                              e.target.value
                            )
                          }
                        />
                     </div>
                     <div className="px-0 col-1 col-md-1 col-lg-1 d-lg-block d-none d-md-block text-end">
                       <IconButton color="error" onClick={() => deleteItem(categoryIndex, itemIndex)} size='small' ><DeleteIcon /></IconButton>
                     </div>
                  </div>
              ))}
              <div className="w-100 mt-4">
                 <Button color='success' variant='contained' className='me-2' onClick={() => addNewItem(categoryIndex)}>+Add New Item</Button>
                 {/* <Button color='error' variant="contained" onClick={() => deleteCategory(categoryIndex)}> <i className=''></i> Delete Category</Button> */}
                 <Button startIcon={<DeleteIcon/>} aria-label="delete" color='error' variant='contained' onClick={() => deleteCategory(categoryIndex)}>Delete Brand</Button>
              </div>
      </div>
      ))}
      <div className="price-list-floation-btn">
        <Fab color="primary" aria-label="add" variant="extended" onClick={addNewCategory}>
          <i className='bi bi-plus'></i>
          Add New Category
        </Fab>
        <Fab variant="extended" color='success' onClick={handleSubmit}>
          <i className='bi bi-floppy me-2'></i>
          {loading ? <Loading theme="text-white"/> : "Save Price List"}
        </Fab>
      </div>
       {/* Delete Confirmation Dialog */}
       <Dialog open={deleteConfirmationOpen} onClose={cancelDeleteCategory}>
        <DialogTitle>Delete Category</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this category?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDeleteCategory}>Cancel</Button>
          <Button onClick={confirmDeleteCategory} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
      <div className="min-vh-30" id='bottom-div'></div>
    </div>
  );
};

ItemsHandler.defaultProps = {
  priceList: {
    name: "",
    details: [
      {
        categoryName: "",
        categoryItems: []
      }
    ]
  },
  actionType: "edit"
} 


export default ItemsHandler;

