import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTopCustomers } from '../../redux/actions/analyticsAction'
import Loader from '../../utility/Loader'
import moment from 'moment'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers'

import LineChart from '../../components/charts/LineChart'
import { useNavigate } from 'react-router-dom'


const TopCustomers = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { topCustomers, loading } = useSelector((state) => state.analytics);
  
    // const [from, setFrom] = useState(moment(window.sessionStorage.getItem("from") ? window.sessionStorage.getItem("from") : "Sun Oct 01 2023 00:00:00 GMT+0530").startOf('day'));
    const [from, setFrom] = useState(window.sessionStorage.getItem("from") ? moment(window.sessionStorage.getItem("from")).startOf('day') : moment().subtract(1, 'months').startOf('day'))
    const [to, setTo] = useState(window.sessionStorage.getItem("to") ? moment(window.sessionStorage.getItem("to")).startOf('day') : moment().startOf('day'));
    const [name, setName] = useState()
    const [filters, setFilters] = useState({ from, to, name });   

  
    useEffect(() => {
      if (topCustomers.length === 0) {
        dispatch(getTopCustomers(filters));
      }
    }, []);

      const submit = () => {
        dispatch(getTopCustomers({from, to, name}));
        console.log(filters)
      }

      const handleNameSearch = (customerName) => {
        setName(customerName)
        setFilters({from, to, name: customerName})
      }

      const setNewChartData = () => {
        const newData = {
            labels: topCustomers.map((data) => data._id),
            datasets: [{
              label: "Sales in INR",
              data: topCustomers.map((data) => data.totalAmount),
              backgroundColor: '#6f42c1',
            }]
        }

        return newData
      }

      const [chartData, setChartData] = useState(setNewChartData())


      useEffect(() => {
        setChartData(setNewChartData())
      }, [topCustomers]);

      const redirectToFilterChallansPage = (name) => {
        navigate(`/analytics/accounts?from=${from}&to=${to}&name=${name}&exactNameCheck=true`)
      }

      const handleDateChange = (type, value) => {
        if (type === "from") {
          setFrom(value)
        } else {
          setTo(value)
        }
        window.sessionStorage.setItem(type, value);
        setFilters({ ...filters, [type]: value });
      }

    const inrFormatter = (value) =>
      new Intl.NumberFormat('en-IN').format(value);

      
    const handlePrint = () => {
      window.print();
    };


  return loading ? (<Loader/>) : (
    <section className='my-5 container-fluid'>
      <div className="pb-5"><h2 className="text-primary text-center">Top Customer</h2></div>
        {/* Filters */}
        <div className="d-flex gap-5 flex-wrap">
                <div className="position-relative d-flex mr-3">
                <span>From - </span>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-gb'}>
                    <MobileDatePicker
                    value={from}
                    onChange={(newValue) => {
                        handleDateChange("from", newValue)
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                        <>
                        <p className="text-primary text-underline fw-bold cursor-pointer" ref={inputRef} {...inputProps}>
                            {moment(from).startOf('day').format('D MMM YYYY')}
                        </p>
                        {InputProps?.endAdornment}
                        </>
                    )}
                    />
                </LocalizationProvider>
                </div>
                <div className="position-relative d-flex">
                <span>To - </span>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-gb'}>
                    <MobileDatePicker
                    value={to}
                    onChange={(newValue) => {
                        handleDateChange("to", newValue)
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                        <>
                        <p className="text-primary text-underline fw-bold cursor-pointer" ref={inputRef} {...inputProps}>
                            {moment(to).startOf('day').format('D MMM YYYY')}
                        </p>
                        {InputProps?.endAdornment}
                        </>
                    )}
                    />
                </LocalizationProvider>
                </div>
        </div>
        {/* Search & Submit */}
        <div className='flex-between gap-5'>
          <div class="input-group my-3">
            <input value={name} onChange={(e)=>handleNameSearch(e.target.value)} autoComplete='off' type="text" class="form-control" placeholder="Name" aria-label="Recipient's username" aria-describedby="button-addon2"/>
            <button class="btn btn-primary" type="button" id="button-addon2" onClick={submit}>Search</button>
          </div>
        </div>
        <button className='btn btn-sm btn-success my-4 d-print-none' onClick={handlePrint}>Print</button>
        {/* Chart */}
        <div className="my-5">
            <LineChart chartData={chartData} />
        </div>
        {/* List */}
        <div className="mt-5">
            <div className="row bg-dark text-white">
                <div className="col-2 col-lg-1">#</div>
                <div className="col-8 col-lg-9">Name</div>
                <div className="col-2 text-end">Total</div>
            </div>
            {topCustomers?.map((item, index)=>(
                <div onClick={()=>redirectToFilterChallansPage(item._id)} className={`row cursor-pointer hover-bg-dark border ${index%2===0 ? 'bg-white' : 'bg-gray-300'}`}>
                    <div className="col-2 col-lg-1">{index + 1}</div>
                    <div className="col-8 col-lg-9">{item._id}</div>
                    <div className="col-2 text-end">₹ {inrFormatter(item.totalAmount)}</div>
                </div>
            ))}
        </div>
    </section>
  )
}

export default TopCustomers