import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Loader from './Loader'
import { useDispatch, useSelector } from 'react-redux'
import { getBills } from '../redux/actions/billAction'

const BillsLoader = () => {

  const dispatch = useDispatch()
  const {bills, loading} = useSelector(state=>state.bills)

  useEffect(() => {
    if (bills.length===0) {
        dispatch(getBills())
      }
}, [])

  return loading ? <Loader /> : <Outlet />

}

export default BillsLoader