import {
    ALL_PRICELIST_FAIL,
    ALL_PRICELIST_REQUEST,
    ALL_PRICELIST_SUCCESS, 
    PRICELIST_DETAILS_REQUEST,
    PRICELIST_DETAILS_SUCCESS,
    PRICELIST_DETAILS_FAIL,
    CREATE_PRICELIST_FAIL,
    CREATE_PRICELIST_REQUEST,
    CREATE_PRICELIST_SUCCESS,
    DELETE_PRICELIST_FAIL,
    DELETE_PRICELIST_REQUEST,
    DELETE_PRICELIST_SUCCESS,
    UPDATE_PRICELIST_FAIL,
    UPDATE_PRICELIST_REQUEST,
    UPDATE_PRICELIST_SUCCESS,
    CLEAR_ERRORS
} from "../constants/priceListConstant"

const initialState = {
    priceLists: [],
    priceList: {},
    priceListsCount: 0, 
    length: 0,
}

export const priceListReducer = (state = initialState, action) => {

    switch (action.type) {
        case ALL_PRICELIST_REQUEST:
            
            return {
                ...state,
                loading: true,
            }
        
        case ALL_PRICELIST_SUCCESS:
        
            return {
                ...state,
                loading: false,
                priceLists: action.payload.allPriceLists,
                priceListsCount: action.payload.allPriceLists.length
            }

        case ALL_PRICELIST_FAIL:
    
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        
        case PRICELIST_DETAILS_REQUEST:
    
            return {
                ...state,
                loading: true,
            }
        
        case PRICELIST_DETAILS_SUCCESS:
        
            return {
                ...state,
                loading: false,
                priceList: action.payload,
            }

        case PRICELIST_DETAILS_FAIL:
    
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CREATE_PRICELIST_REQUEST:
        
            return {
                ...state,
            }
        
        case CREATE_PRICELIST_SUCCESS:
        
            return {
                ...state,
                priceLists: [action.payload.priceList, ...state.priceLists],
                message: action.payload.message,
                loading: false,
                success: true
            }

        case CREATE_PRICELIST_FAIL:
    
            return {
                ...state,
                error: action.payload
            }
        
        case DELETE_PRICELIST_REQUEST:
    
            return {
                ...state,
            }
        
        case DELETE_PRICELIST_SUCCESS:
        
            return {
                // ...state,
                // success: true,
                ...state,
                priceLists: state.priceLists.filter((i)=>i._id !== action.payload),
                success: true,
                message: "PriceList Deleted Successfully"
            }

        case DELETE_PRICELIST_FAIL:
    
            return {
                ...state,
                error: action.payload
            }
        
        case UPDATE_PRICELIST_REQUEST:
    
            return {
                ...state,
            }
        
        case UPDATE_PRICELIST_SUCCESS:
        
            // return {
            //     ...state,
            //     priceList: action.payload.priceList,
            //     success: true,
            //     message: "PriceList updated Successfully"
            // }
        
            const item = action.payload.priceList

            const isItemExists = state.priceLists.find((i) => i._id === item._id)

            if (isItemExists) {
                return {
                    ...state,
                    success: true,
                    message: "PriceList updated Successfully",
                    loading: false,
                    priceLists: state.priceLists.map((i)=>
                        i._id === isItemExists._id ? item : i
                    )
                }
            }
            

        case UPDATE_PRICELIST_FAIL:
    
            return {
                ...state,
                error: action.payload
            }

        case CLEAR_ERRORS:
    
            return {
                ...state,
                error: null
            }
    
        default:
            return state
    }

}
