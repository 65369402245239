import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Loader from './Loader'
import { useDispatch, useSelector } from 'react-redux'
import { getPriceLists } from '../redux/actions/priceListAction'

const PriceListLoadedRoute = () => {

  const dispatch = useDispatch()
  const {priceLists, loading} = useSelector(state=>state.priceLists)

  useEffect(() => {
    if (priceLists.length===0) {
        dispatch(getPriceLists())
      }
}, [])

  return loading ? <Loader /> : <Outlet />

}

export default PriceListLoadedRoute