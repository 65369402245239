import {
    ALL_PI_FAIL,
    ALL_PI_REQUEST,
    ALL_PI_SUCCESS, 
    PI_DETAILS_REQUEST,
    PI_DETAILS_SUCCESS,
    PI_DETAILS_FAIL,
    CREATE_PI_FAIL,
    CREATE_PI_REQUEST,
    CREATE_PI_SUCCESS,
    DELETE_PI_FAIL,
    DELETE_PI_REQUEST,
    DELETE_PI_SUCCESS,
    UPDATE_PI_FAIL,
    UPDATE_PI_REQUEST,
    UPDATE_PI_SUCCESS,
    CLEAR_ERRORS
} from "../constants/performaInvoiceConstant"

const initialState = {
    performaInvoices: [],
    performaInvoice: {},
    performaInvoicesCount: 0, 
    length: 0,
}

export const performaInvoiceReducer = (state = initialState, action) => {

    switch (action.type) {
        case ALL_PI_REQUEST:
            
            return {
                ...state,
                loading: true,
            }
        
        case ALL_PI_SUCCESS:
        
            return {
                ...state,
                loading: false,
                performaInvoices: action.payload.allPerformaInvoices,
                performaInvoicesCount: action.payload.allPerformaInvoices.length
            }

        case ALL_PI_FAIL:
    
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        
        case PI_DETAILS_REQUEST:
    
            return {
                ...state,
                loading: true,
            }
        
        case PI_DETAILS_SUCCESS:
        
            return {
                ...state,
                loading: false,
                performaInvoice: action.payload,
            }

        case PI_DETAILS_FAIL:
    
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CREATE_PI_REQUEST:
        
            return {
                ...state,
            }
        
        case CREATE_PI_SUCCESS:
        
            return {
                ...state,
                performaInvoices: [action.payload.performaInvoice, ...state.performaInvoices],
                message: action.payload.message,
                loading: false,
                success: true
            }

        case CREATE_PI_FAIL:
    
            return {
                ...state,
                error: action.payload
            }
        
        case DELETE_PI_REQUEST:
    
            return {
                ...state,
            }
        
        case DELETE_PI_SUCCESS:
        
            return {
                // ...state,
                // success: true,
                ...state,
                performaInvoices: state.performaInvoices.filter((i)=>i._id !== action.payload),
                success: true,
                message: "PerformaInvoice Deleted Successfully"
            }

        case DELETE_PI_FAIL:
    
            return {
                ...state,
                error: action.payload
            }
        
        case UPDATE_PI_REQUEST:
    
            return {
                ...state,
            }
        
        case UPDATE_PI_SUCCESS:
        
            // return {
            //     ...state,
            //     performaInvoice: action.payload.performaInvoice,
            //     success: true,
            //     message: "PerformaInvoice updated Successfully"
            // }
        
            const item = action.payload.performaInvoice

            const isItemExists = state.performaInvoices.find((i) => i._id === item._id)

            if (isItemExists) {
                return {
                    ...state,
                    success: true,
                    message: "PerformaInvoice updated Successfully",
                    loading: false,
                    performaInvoices: state.performaInvoices.map((i)=>
                        i._id === isItemExists._id ? item : i
                    )
                }
            }
            

        case UPDATE_PI_FAIL:
    
            return {
                ...state,
                error: action.payload
            }

        case CLEAR_ERRORS:
    
            return {
                ...state,
                error: null
            }
    
        default:
            return state
    }

}
