import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Loader from './Loader'

const PrivateRoute = () => {

  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  const logoutUser = (route) => {
    localStorage.clear()
    navigate(route)
  }

  useEffect(() => {
    const user = localStorage.getItem("token")
    if (!user) {
      logoutUser("/login")
    } else {
      setLoading(false)
    }
  }, [])

  return loading ? <Loader /> : <Outlet />

}

export default PrivateRoute