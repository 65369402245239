import {
    TOP_ITEMS_SOLD_FAIL,
    TOP_ITEMS_SOLD_REQUEST,
    TOP_ITEMS_SOLD_SUCCESS, 
    TOTAL_SALES_FAIL,
    TOTAL_SALES_REQUEST,
    TOTAL_SALES_SUCCESS,
    TOP_CUSTOMERS_FAIL,
    TOP_CUSTOMERS_REQUEST,
    TOP_CUSTOMERS_SUCCESS,
    GET_CUSTOMERS_FAIL,
    GET_CUSTOMERS_REQUEST,
    GET_CUSTOMERS_SUCCESS,
    FILTER_CHALLANS_FAIL,
    FILTER_CHALLANS_REQUEST,
    FILTER_CHALLANS_SUCCESS,
    TOTAL_TAX_SALES_FAIL,
    TOTAL_TAX_SALES_REQUEST,
    TOTAL_TAX_SALES_SUCCESS,
    CLEAR_ERRORS
} from "../constants/analyticsConstant"

const initialState = {
    topItemsSold: [],
    totalItemsSold: 0,
    totalSalesData: [],
    totalSalesAmount: 0,
    topCustomers: [],
    filteredChallans: [],
    totalTaxSalesData: [],
    totalTaxSalesAmount: 0,
    totalTaxAmount: 0,
    customers: []

}

export const analyticsReducer = (state = initialState, action) => {

    switch (action.type) {
        case TOP_ITEMS_SOLD_REQUEST:
            
            return {
                ...state,
                loading: true,
            }
        
        case TOP_ITEMS_SOLD_SUCCESS:
        
            return {
                ...state,
                loading: false,
                topItemsSold: action.payload.topItemsSold,
                totalItemsSold: action.payload.totalItemsSold,
            }

        case TOP_ITEMS_SOLD_FAIL:
    
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case TOTAL_SALES_REQUEST:
            
            return {
                ...state,
                loading: true,
            }
        
        case TOTAL_SALES_SUCCESS:
        
            return {
                ...state,
                loading: false,
                totalSalesData: action.payload.totalSalesData,
                totalSalesAmount: action.payload.totalSalesAmount,
            }

        case TOTAL_SALES_FAIL:
    
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case TOP_CUSTOMERS_REQUEST:
            
            return {
                ...state,
                loading: true,
            }
        
        case TOP_CUSTOMERS_SUCCESS:
        
            return {
                ...state,
                loading: false,
                topCustomers: action.payload.topCustomers,
            }

        case TOP_CUSTOMERS_FAIL:

            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case FILTER_CHALLANS_REQUEST:
            
            return {
                ...state,
                loading: true,
            }
        
        case FILTER_CHALLANS_SUCCESS:
        
            return {
                ...state,
                loading: false,
                filteredChallans: action.payload.filteredChallans,
            }

        case FILTER_CHALLANS_FAIL:

            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case TOTAL_TAX_SALES_REQUEST:
            
            return {
                ...state,
                loading: true,
            }
        
        case TOTAL_TAX_SALES_SUCCESS:
        
            return {
                ...state,
                loading: false,
                totalTaxSalesData: action.payload.totalTaxSalesData,
                totalTaxSalesAmount: action.payload.totalTaxSalesAmount,
                totalTaxAmount: action.payload.totalTaxAmount,
            }

        case TOTAL_TAX_SALES_FAIL:
    
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case GET_CUSTOMERS_REQUEST:
            
            return {
                ...state,
                loading: true,
            }
        
        case GET_CUSTOMERS_SUCCESS:
        
            return {
                ...state,
                loading: false,
                customers: action.payload.customers,
            }

        case GET_CUSTOMERS_FAIL:
    
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
    
            return {
                ...state,
                error: null
            }
    
        default:
            return state
    }

}
