import React, { useEffect, useState } from 'react'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
// import { getChallans } from '../../redux/actions/challanActions';
import { getPriceLists } from '../../redux/actions/priceListAction';
// import { filterChallans } from '../../utility/challans';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loader from '../../utility/Loader';
import { getCustomers } from '../../redux/actions/analyticsAction';

const ChallanDetailsHandler = ({name, setName, address, setAddress, transporterName, setTransporterName, marka, setMarka, nag, setNag, children, note, setNote, selectedPriceList, setSelectedPriceList}) => {

    const {customers, loading} = useSelector(state=>state.analytics)
    const {priceLists} = useSelector(state=>state.priceLists)
    const dispatch = useDispatch()

    useEffect(() => {
      dispatch(getCustomers())
    }, [])
  
    const handleAutoCompleteForChallans = (e, detials, type) => {
      if (type==="onChange") {
        // onChange means that the value is being selected by clicking
        setName(detials._id)
        setAddress(detials.address ? detials.address : "")
        setTransporterName(detials.transporterName ? detials.transporterName : "")
        setMarka(detials.marka ? detials.marka : "")
        setSelectedPriceList(setPriceListHandler(detials))
      } else {
        setName(detials)
      }
    }
  
      // Clear Btn Handler
      setTimeout(async () => {
        const nameAutocomplete = document.querySelector(".name-autocomplete");
        const clearIndicator = nameAutocomplete.querySelector(".MuiAutocomplete-clearIndicator");
        clearIndicator.addEventListener("click", () => {
          setName("")
          setAddress("")
          setTransporterName("")
          setMarka("")
        });
      }, 100);

      const setPriceListHandler = (details) => {
        let priceListToBeSelected
        if (details.priceList) {
          const isItemExists = priceLists.find((i) => i.name === details.priceList)
          return isItemExists
        } else {
          return priceListToBeSelected
        }
      }
  
  
    return loading ? <Loader/> : (
      <>
            <div className="row py-2">
                <div className="col-sm-12 col-lg-6">
                    <Autocomplete
                      className='name-autocomplete mt-4'
                      disablePortal
                      id="combo-box-demo"
                      autoHighlight
                      freeSolo
                      options={customers}
                      defaultValue={{_id: name}}
                      getOptionLabel={(option) => option._id}
                      renderInput={(params) => <TextField {...params} label="Name" />}
                      // value={newItemDetails.name}
                      // This is the display value
                      onInputChange={(event, newInputValue) => {
                        handleAutoCompleteForChallans(event, newInputValue, "onInputChange");
                      }}
                      // This is the actual value
                      onChange={(event, newValue) => {
                        handleAutoCompleteForChallans(event, newValue, "onChange");
                      }}
                    />
                </div>
                <div className="col-sm-12 col-lg-6">
                    <TextField label="Address" variant="outlined" type="text" className="w-100 mt-4"
                    value={address}
                    onChange={(e)=>setAddress(e.target.value)}/>
                </div>
                <div className="col-sm-12 col-lg-6">
                    <TextField label="Note" variant="outlined" type="text" className="w-100 mt-4"
                    value={note}
                    onChange={(e)=>setNote(e.target.value)}/>
                </div>
                <div className="col-sm-12 col-lg-6">
                  <FormControl className="w-100 mt-4">
                    <InputLabel id="demo-simple-select-label">Price List</InputLabel>
                      <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedPriceList ? selectedPriceList : ''}
                          label="Price List"
                          onChange={(e) => setSelectedPriceList(e.target.value)}
                        >
                          {priceLists.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                  </FormControl>
                </div>
            </div>
            {children}
            <div className="row">
                <div className="col-sm-12 col-lg-6">
                    <TextField label="Transporter Name" variant="outlined" type="text" className="w-100 mt-4"
                    value={transporterName}
                    onChange={(e)=>setTransporterName(e.target.value)}
                    />
                </div>
                <div className="col-sm-12 col-lg-6 row mx-0 px-0">
                    <div className="col">
                      <TextField label="Marka" variant="outlined" type="text" className="w-100 mt-4"
                      value={marka}
                      onChange={(e)=>setMarka(e.target.value)}
                      />
                    </div>
                    <div className="col">
                      <TextField label="Nag" variant="outlined" type="text" className="w-100 mt-4"
                      value={nag}
                      onChange={(e)=>setNag(e.target.value)}
                      />
                    </div>
                </div>
            </div>
      </>
    )
  }
  
  export default ChallanDetailsHandler