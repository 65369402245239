import React from 'react';
import LastPage from './CoverPages/LastPage';
import FirstPage from './CoverPages/FirstPage';

const PrintPriceList = ({ priceList }) => {
  return (
    <div className='price-list-print-page'>
      <FirstPage/>
      <div className="content-page">
        <div className="left-side-branding" style={{backgroundImage:"url(/assets/img/priceList/1.jpg)"}}>
          <img src="/assets/img/priceList/2.svg" alt="" />
        </div>
        <div className="right-side-content">
          {priceList && 
            <>
              {priceList.details.map((category, index) => (
                category.categoryName.toLowerCase() !== 'miscellaneous' && (
                  <div className='brand-div' key={index}>
                    <h4 className='brand-name'>{category.categoryName}</h4>
                    {category.categoryItems && category.categoryItems.map((item, indexItems) => (
                      <div className={`row w-100 ${indexItems % 2 === 0 ? 'bg-gray-200' : 'bg-gray-300'}`} key={indexItems}>
                        <div className="col-7"><p>{item.name}</p></div>
                        <div className="col-2"><p>{item.position}</p></div>
                        <div className="col-1 text-end"><p>1</p></div>
                        <div className="col-2 text-end"><p>{Math.round(item.price)}.00</p></div>
                      </div>
                    ))}
                    <div className='dividing-div'></div>
                  </div>
                )
              ))}
              <p className='footer-note-price-list'>Note: Logos, Vehicle Names on products and packing are not related to us. these are used only for item identification.</p>
            </>
          }
        </div>
      </div>
      <LastPage/>
    </div>
  );
};

export default PrintPriceList;
