export const ALL_PI_REQUEST = "ALL_PI_REQUEST"
export const ALL_PI_SUCCESS = "ALL_PI_SUCCESS"
export const ALL_PI_FAIL = "ALL_PI_FAIL"
export const PI_DETAILS_REQUEST = "PI_DETAILS_REQUEST"
export const PI_DETAILS_SUCCESS = "PI_DETAILS_SUCCESS"
export const PI_DETAILS_FAIL = "PI_DETAILS_FAIL"
export const CREATE_PI_REQUEST = "CREATE_PI_REQUEST"
export const CREATE_PI_SUCCESS = "CREATE_PI_SUCCESS"
export const CREATE_PI_FAIL = "CREATE_PI_FAIL"
export const DELETE_PI_REQUEST = "DELETE_PI_REQUEST"
export const DELETE_PI_SUCCESS = "DELETE_PI_SUCCESS"
export const DELETE_PI_FAIL = "DELETE_PI_FAIL"
export const UPDATE_PI_REQUEST = "UPDATE_PI_REQUEST"
export const UPDATE_PI_SUCCESS = "UPDATE_PI_SUCCESS"
export const UPDATE_PI_FAIL = "UPDATE_PI_FAIL"
export const CLEAR_ERRORS = "CLEAR_ERRORS"