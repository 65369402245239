import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { createChallan } from '../../redux/actions/challanActions'
import moment from 'moment'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers'

import ChallanDetailsHandler from '../../components/Challans/ChallanDetailsHandler'
import ItemsHandler from '../../components/Challans/ItemsHandler'
import { addItemsToCategory, newItemsIdentifier } from '../../utility/products'
import { updatePriceList } from '../../redux/actions/priceListAction'

const NewChallan = () => {
    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [error, setError] = useState()

    
    const [date, setDate] = useState(moment())
    const [name, setName] = useState("")
    const [address, setAddress] = useState("")
    const [transporterName, setTransporterName] = useState("")
    const [marka, setMarka] = useState("")
    const [nag, setNag] = useState("")
    const [totalAmount, setTotalAmount] = useState()
    const [items, setItems] = useState([])
    const [note, setNote] = useState("")
    const [selectedPriceList, setSelectedPriceList] = useState()
    const [loading, setLoading] = useState(false)

    const [newItemDetails, setNewItemDetails] = useState({
      name: '',
      quantity: '',
      price: '',
      totalAmount: 0,
      randomId: 0
    })
    

    const challanHandler = (action) => {
      setLoading(true)
      newItemsEditor(action)
    }

    const newItemsEditor = (action) => {
      const newItems = newItemsIdentifier(selectedPriceList, items)
      let newPriceList = selectedPriceList
      if (newItems.length > 0) {
        newPriceList = addItemsToCategory(newItems, 'miscellaneous', selectedPriceList);
        dispatch(updatePriceList(newPriceList._id, newPriceList))
        .then(res => {
          if (res.success) {
            createChallanHandler(action)
          } else {
            setError(res.message)
          }
        })
      } else {
        createChallanHandler(action)
      }
    }

    const createChallanHandler = (action) => {
      const data = {
        name: name.trim(),
        totalAmount: totalAmount,
        items: items,
        address: address,
        marka: marka,
        nag: nag,
        note: note,
        transporterName: transporterName,
        priceList: selectedPriceList.name,
        createdAt: JSON.stringify(date).substring(1, JSON.stringify(date).length -1)
      }
      dispatch(createChallan(data))
        .then(res => {
          if (res.success) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            action === "saveAndPrint" ? navigate(`/challan/print/${res.challan._id}?print=true`) : navigate('/')
          } else {
            setError(res.message)
          }
          setLoading(false)
        })
    }

  return (
      <section className='pb-5'>
         <div className="d-flex flex-wrap align-items-center justify-content-between">
            <h2 className='fw-bold text-primary'>New Challan</h2>
            <div className='position-relative d-flex'>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-gb"}>
                  <MobileDatePicker
                      value={date}
                      onChange={(newValue) => {
                          setDate(newValue);
                      }}
                      renderInput={({ inputRef, inputProps, InputProps }) => (
                          <>
                            <h2 className='text-primary text-underline fw-bold cursor-pointer' ref={inputRef} {...inputProps}>{moment(date).format('D MMM YYYY')}</h2>
                            {InputProps?.endAdornment}
                          </>
                      )}
                  />
                </LocalizationProvider>
              </div>
          </div>
          <ChallanDetailsHandler name={name} setName={setName} address={address} setAddress={setAddress} transporterName={transporterName} setTransporterName={setTransporterName} marka={marka} setMarka={setMarka} nag={nag} setNag={setNag} note={note} setNote={setNote} selectedPriceList={selectedPriceList} setSelectedPriceList={setSelectedPriceList} >
              <ItemsHandler items={items} setItems={setItems} totalAmount={totalAmount} setTotalAmount={setTotalAmount} newItemDetails={newItemDetails} setNewItemDetails={setNewItemDetails} selectedPriceList={selectedPriceList} />
          </ChallanDetailsHandler>
          <div className="row">
            <div className="col-lg-2 col-sm-6">
              <button className='w-100 btn btn-success mt-4 me-3' onClick={()=>challanHandler("saveAndPrint")} disabled={newItemDetails.name}>{loading ? <div className="spinner-border button-spinner" role="status"><span className="visually-hidden">Loading...</span></div> : "Save & Print"}</button>
            </div>
            <div className="col-lg-2 col-sm-6">
              <button className='w-100 btn btn-primary mt-4' onClick={()=>challanHandler("save")} disabled={newItemDetails.name}>{loading ? <div className="spinner-border button-spinner" role="status"><span className="visually-hidden">Loading...</span></div> : "Save"}</button>
            </div>
          </div>
          {newItemDetails.name && <p className="text-danger">Please Save Item before proceding further</p>}
          {error && <p className="text-danger">{error}</p>}            
      </section>
  )
}

export default NewChallan