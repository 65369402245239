import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBillDetails } from '../../redux/actions/billAction'
import Loader from '../../utility/Loader'
import PrintHandler from '../../components/BillingSystem/PrintHandler'

const PrintBill = () => {

    const { id } = useParams()
    const dispatch = useDispatch()
    const {bills} = useSelector(state=>state.bills)

    const [bill, setBill] = useState()
    const [loading, setLoading] = useState(true)
    const [printCount, setPrintCount] = useState(0)
    let printBill = (new URLSearchParams(window.location.search)).get("print")

    useEffect(() => {
        const isBillExist = bills.find((i) => i._id === id)
        if (isBillExist) {
            setBill(isBillExist)
            setLoading(false)
        } else {
            dispatch(getBillDetails(id)).then((res) => {
                if (res.success) {
                    setBill(res.bill)
                    setLoading(false)
                }
            })
        }
    }, [])

    useEffect(() => {
        if (bill && bill.items && printCount<1 && printBill) {
            setTimeout(() => {
                setPrintCount(printCount + 1)
                handlePrint()
            }, 1000);
        }
    }, [bill, printCount])
    

    const handlePrint = () => {
        window.print();
      };

  return loading ? (<Loader/>) : 
  (
    <section className='billing-system'>
        <PrintHandler details={bill} type={"bill"} copyType={"Original Copy"} />
        <PrintHandler details={bill} type={"bill"} copyType={"Duplicate Copy"} />
        <PrintHandler details={bill} type={"bill"} copyType={"Triplicate Copy"} />
    </section>
  )
}

export default PrintBill