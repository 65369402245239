// Remove Category name and add all items in one product array for autocomplete component
export const priceListItemsBundler = (priceList) => {
    if (priceList) {
        // New Pice List without categories
        let newPriceList = []
        for (let i = 0; i < priceList.details.length; i++) {
            const category = priceList.details[i];
            for (let index = 0; index < category.categoryItems.length; index++) {
                const item = category.categoryItems[index];
                newPriceList.push(item)
            }
        }
        return newPriceList
    }
}

export const newItemsIdentifier = (priceList, challanItems) => {
    const priceListItems = priceListItemsBundler(priceList)
    const newItems = challanItems.filter(challanItem =>
        !priceListItems.some(priceListItem => priceListItem.name.toLowerCase() === challanItem.name.toLowerCase())
      );
    return newItems
}

export const addItemsToCategory = (newItems, categoryName, priceList) => {
    // Check if the specified category already exists
    const categoryIndex = priceList.details.findIndex(
      (category) => category.categoryName === categoryName
    );
  
    // If the category exists, add items to it
    if (categoryIndex !== -1) {
      const itemsToAdd = newItems.map((item) => ({
        name: item.name,
        price: item.price,
        position: "L/R",
      }));
      priceList.details[categoryIndex].categoryItems.push(...itemsToAdd);
    } else {
      // If the category doesn't exist, create a new one and add items to it
      const itemsToAdd = newItems.map((item) => ({
        name: item.name,
        price: item.price,
        position: "L/R",
      }));
  
      const newCategory = {
        categoryName: categoryName,
        categoryItems: itemsToAdd,
      };
      priceList.details.push(newCategory);
    }

    return priceList

  };

const priceListDemoSchema = {
  name: "Demo Price List",
  details: [
    {
      categoryName: "Maruti",
      categoryItems: [
        {name: "A", price: 150, position: "L/R"},
        {name: "B", price: 150, position: "L/R"},
        {name: "C", price: 150, position: "L/R"},
        {name: "D", price: 150, position: "L/R"},
      ]
    },
    {
      categoryName: "Honda",
      categoryItems: [
        {name: "A", price: 150, position: "L/R"},
        {name: "B", price: 150, position: "L/R"},
        {name: "C", price: 150, position: "L/R"},
        {name: "D", price: 150, position: "L/R"},
      ]
    },
    {
      categoryName: "Suzuki",
      categoryItems: [
        {name: "A", price: 150, position: "L/R"},
        {name: "B", price: 150, position: "L/R"},
        {name: "C", price: 150, position: "L/R"},
        {name: "D", price: 150, position: "L/R"},
      ]
    },
  ]
}
