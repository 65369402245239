import {createStore, combineReducers, applyMiddleware} from "redux"
import thunk from "redux-thunk"
import {composeWithDevTools} from "redux-devtools-extension"
import { challanReducer } from "./reducers/challanReducer"
import { userReducer } from "./reducers/userReducer"
import { priceListReducer } from "./reducers/priceListReducer"
import { billReducer } from "./reducers/billReducer"
import { analyticsReducer } from "./reducers/analyticsReducer"
import { performaInvoiceReducer } from "./reducers/performaInvoiceReducer"

const reducer = combineReducers({
    challans:challanReducer,
    bills:billReducer,
    performaInvoices:performaInvoiceReducer,
    priceLists:priceListReducer,
    user: userReducer,
    analytics: analyticsReducer,
})

let initialState={}

const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store;