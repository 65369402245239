import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { getChallans, updateChallanPage } from '../../redux/actions/challanActions'
import moment from 'moment/moment'
import DeleteChallan from '../../components/Challans/DeleteChallan'
import ViewChallan from '../../components/Challans/ViewChallan'
import Loader from '../../utility/Loader'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const Challans = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {challans, challansCount , loading, challanPage, challansPerPage} = useSelector(state=>state.challans)
    const [selectedChallanId, setSelectedChallanId] = useState()
    const [selectedChallan, setSelectedChallan] = useState()

    // Search States
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredChallans, setFilteredChallans] = useState(challans);


    useEffect(() => {
        if (challans.length===0) {
            dispatch(getChallans(challanPage, challansPerPage))
          }
    }, [])

    const handlePageChange = (event, value) => {
      dispatch(updateChallanPage(value))
      dispatch(getChallans(value, challansPerPage))
    };

    useEffect(() => {
      if (challans.length>0) {
        setFilteredChallans(challans)
      }
    }, [challans])
    

    const handleDelete = (id) => {
    setSelectedChallanId(id)
    document.getElementById("openDeleteChallanModal").click()
  }

  // Function to update the filtered challans based on the search term
  const updateFilteredChallans = (term) => {
    const filtered = challans.filter((product) =>
      product.name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredChallans(filtered);
  };

  // Event handler for input change
  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    updateFilteredChallans(term);
  };

  const navigateToViewChallan = (id) => (
    navigate(`/challan/print/${id}`)
  )

  const handleViewChallan = (challan) => {
    setSelectedChallan(challan)
    document.getElementById("openViewChallanModal").click()
  }

  return loading ? (<Loader/>) : (
    <section>
    <div>
      <div className="challan-search">
        <div className="flex-between flex-wrap w-100">
          <h2>All Challans ({challans && challansCount})</h2>
          <Pagination color="primary" size="small" defaultPage={challanPage} count={Math.ceil(challansCount/challansPerPage)} onChange={handlePageChange} />
        </div>
        <div className="input-group my-3">
            <input type="text" className="form-control" placeholder="Search Challan" aria-label="Seach Challan" aria-describedby="basic-addon" value={searchTerm} onChange={handleInputChange}/>
            <span className="input-group-text" id="basic-addon"><i className="bi bi-search"></i></span>
          </div>
        {/* <input className='form-control w-auto' type="text" placeholder="Search" value={searchTerm} onChange={handleInputChange}/> */}
      </div>
      {filteredChallans.map((item, index) => (
      <div key={index} className="d-flex flex-column flex-lg-row align-items-start justify-content-start align-items-lg-center justify-content-lg-between flex-wrap py-3 gap-3">
        <div className="flex-grow-1 w-full w-lg-auto">
          <div onClick={()=>navigateToViewChallan(item._id)} className={`cursor-pointer shadow d-flex flex-wrap align-items-start justify-content-between text-white p-3 rounded-2 fw-regular ${index%2===0 ? 'bg-primary' : 'bg-new-secondary'}`}>
            <div className='d-flex'>
              <div className="me-2">{item.challanNumber}</div>
              <div className="">{item.name}</div>
            </div>
            <div>
              {moment(item.createdAt).format('D MMM YY')}
            </div>
          </div>
        </div>
        <div>
          <button className='p-1 px-3 bg-teal-600 text-white text-xl rounded shadow me-3' onClick={()=>handleViewChallan(item)}><i className='bi bi-eye'></i></button>
          <Link to={`/challan/print/${item._id}?print=true`} className='p-2 px-3 bg-new-success text-white text-xl rounded shadow me-3'><i className='bi bi-printer'></i></Link>
          <Link className='p-2 px-3 bg-new-warning text-white text-xl rounded shadow me-3' to={`/challan/${item._id}`}><i className='bi bi-pencil'></i></Link>
          <button className='p-1 px-3 bg-new-danger text-white text-xl rounded shadow' onClick={()=>handleDelete(item._id)}><i className='bi bi-trash'></i></button>
          <button className='btn btn-primary d-none' data-bs-toggle="modal" data-bs-target="#deleteChallan" id='openDeleteChallanModal'>Delete challan</button>
          <button className='btn btn-primary d-none' data-bs-toggle="modal" data-bs-target="#viewChallan" id='openViewChallanModal'>View Challan</button>
          <DeleteChallan id={selectedChallanId} />
          <ViewChallan challan={selectedChallan} />
        </div>
      </div>
      ))}

    </div>
</section>
  )
}

export default Challans