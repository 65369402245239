import React from 'react'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const Toaster = ({text, toaster, setToaster, toasterType}) => {

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} {...props} />;
  });

  const handleClick = () => {
    setToaster(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToaster(false);
  };

    return (
      <Stack spacing={2} sx={{ width: '100%' }}>
        <button className='d-none' onClick={handleClick}>Open Toaster</button>
        <Snackbar open={toaster} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert onClose={handleClose} severity={toasterType} sx={{ width: '100%' }}>
            {text}
          </Alert>
        </Snackbar>
      </Stack>
  )
}

export default Toaster