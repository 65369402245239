import React, {useEffect, useState} from 'react'
import Modal from "../../components/utility/Modal"
import Toaster from "../../components/utility/Toaster"
import moment from 'moment'
import html2canvas from 'html2canvas'

const ViewChallan = ({challan}) => {

  const [totalQuantity, setTotalQuantity] = useState(0)
  const [buttonLoading, setButtonLoading] = useState(false)


  const closeModal = () => {
    document.getElementById("closeViewChallanModal").click()
  }

  useEffect(() => {
    if (challan && challan.items) {
        let quantity = 0
        for (let i = 0; i < challan.items.length; i++) {
          const item = challan.items[i];
          quantity = quantity + item.quantity
        }
        setTotalQuantity(quantity)
    }
}, [challan])


    const handleDownloadImage = async () => {
        setButtonLoading(true)
        const element = document.querySelector('.view-challan-div'),
        canvas = await html2canvas(element),
        data = canvas.toDataURL('image/jpg'),
        link = document.createElement('a');

        link.href = data;
        link.download = `${challan.challanNumber} - ${challan.name}`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setButtonLoading(false)
    };

  return (
    <section>
        <Modal targetName={"viewChallan"} modalSettings={'modal-xl'} modalBodyClasses={"p-0"}>
            <div className='p-5 view-challan-div'>
            <div className="row">
                  {challan && 
                      <div>
                          <div className="d-flex align-items-center justify-content-between">
                              <h6 className='text-black'>Chalan No. - {challan.challanNumber} | {challan.note}</h6>
                              <h6 className='text-black'>{moment(challan.createdAt).format('D MMM YY')}</h6>
                          </div>
                          <h6>{challan.name} - {challan.address}</h6>
                          <table className="table table-striped mt-2">
                              <thead>
                                  <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">Item Name</th>
                                      <th scope="col">Qty</th>
                                      <th scope="col">Rate</th>
                                      <th scope="col">Amount</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {challan.items && challan.items.map ((item, index)=>(
                                      <tr key={index}>
                                          <th scope="row">{index + 1}</th>
                                          <td>{item.name}</td>
                                          <td>{item.quantity}</td>
                                          <td>{item.price}</td>
                                          <td>{item.quantity * item.price}</td>
                                      </tr>
                                  ))}
                              </tbody>
                                <tfoot>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col">{totalQuantity}</th>
                                        <th scope="col"></th>
                                        <th scope="col">₹{challan.totalAmount}</th>
                                    </tr>
                                </tfoot>
                          </table>
                          <div className="d-flex align-items-center justify-content-start flex-wrap gap-5 mt-4">
                              {challan.transporterName && <div>
                                  <h6>Transporter Name</h6>
                                  <p className='text-xs'>{challan.transporterName}</p>
                              </div>}
                              {challan.marka && <div>
                                  <h6>Marka</h6>
                                  <p className='text-xs'>{challan.marka}</p>
                              </div>}
                              {challan.nag && <div>
                                  <h6>Nag</h6>
                                  <p className='text-xs'>{challan.nag}</p>
                              </div>}
                          </div>
                      </div>
                  }
              </div>
            </div>
            <div className="row p-5 pt-0">
                <div className="col-12 col-lg-6"><button className='btn btn-success w-100' onClick={handleDownloadImage}>{buttonLoading ? <div className="spinner-border button-spinner" role="status"><span className="visually-hidden">Loading...</span></div> : "Download Image"}</button></div>
                <div className="col-12 col-lg-6 mt-5 mt-lg-0"><button className='btn btn-danger w-100' data-bs-dismiss="modal" id="closeViewChallanModal">Close</button></div>
            </div>
        </Modal>
    </section>
  )
}

export default ViewChallan