import React from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers'
import moment from 'moment';

const HeadingAndDate = ({date, setDate, type, mode}) => {
  return (
    <div className="d-flex flex-wrap align-items-center justify-content-between">
    <h4 className='fw-bold text-primary'>{mode} {type}</h4>
    <div className='position-relative d-flex'>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-gb"}>
          <MobileDatePicker
              value={date}
              onChange={(newValue) => {
                  setDate(newValue);
              }}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                  <>
                    <h4 className='text-primary text-underline fw-bold cursor-pointer' ref={inputRef} {...inputProps}>{moment(date).format('D MMM YYYY')}</h4>
                    {InputProps?.endAdornment}
                  </>
              )}
          />
        </LocalizationProvider>
      </div>
  </div>
  )
}

HeadingAndDate.defaultProps = {
  type: 'Bill',
  mode: 'New'
}

export default HeadingAndDate