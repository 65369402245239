import React from 'react'

const Loading = ({theme}) => {
  return (
    <section className='w-100 h-100 d-flex align-items-center justify-content-center' style={{zIndex:100}}>
        <div className={`spinner-border ${theme}`} role="status">
            <span className="sr-only"></span>
          </div>
    </section>
  )
}

Loading.defaultProps = {
  theme: 'text-priamry'
}

export default Loading