import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { getPriceLists } from '../../redux/actions/priceListAction'
import Loader from '../../utility/Loader'
import DeletePriceList from '../../components/PriceList/DeletePriceList'
import DuplicateHandler from '../../components/PriceList/DuplicatePriceList'

const PriceList = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {priceLists, loading} = useSelector(state=>state.priceLists)
    const [selectedPriceListId, setSelectedPriceListId] = useState()

    // Search States
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredPriceLists, setFilteredPriceLists] = useState(priceLists);


    useEffect(() => {
        if (priceLists.length===0) {
            dispatch(getPriceLists())
          }
    }, [])

    useEffect(() => {
      if (priceLists.length>0) {
        setFilteredPriceLists(priceLists)
      }
    }, [priceLists])
    

    const handleDelete = (id) => {
    setSelectedPriceListId(id)
    document.getElementById("openDeletePriceListModal").click()
  }

  // Function to update the filtered priceLists based on the search term
  const updateFilteredPriceLists = (term) => {
    const filtered = priceLists.filter((product) =>
      product.name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredPriceLists(filtered);
  };

  // Event handler for input change
  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    updateFilteredPriceLists(term);
  };

  const navigateToViewPriceList = (id) => (
    navigate(`/priceList/view/${id}`)
  )

  const handleDuplicate = (id) => {
    setSelectedPriceListId(id)
    document.getElementById("openDuplicateHandlerModal").click()
  }


  return loading ? (<Loader/>) : (
    <section>
    <div>
      <div className="priceList-search">
        <h2>All Price Lists ({priceLists && priceLists.length})</h2>
        <div className="input-group my-3">
            <input type="text" className="form-control" placeholder="Search Price List" aria-label="Seach Price List" aria-describedby="basic-addon" value={searchTerm} onChange={handleInputChange}/>
            <span className="input-group-text" id="basic-addon"><i className="bi bi-search"></i></span>
          </div>
      </div>
      {filteredPriceLists.map((item, index) => (
      <div key={index} className="d-flex flex-column flex-lg-row align-items-start justify-content-start align-items-lg-center justify-content-lg-between flex-wrap py-3 gap-3">
        <div className="flex-grow-1 w-full w-lg-auto">
          <div onClick={()=>navigateToViewPriceList(item._id)} className={`cursor-pointer shadow d-flex flex-wrap align-items-start justify-content-between text-white p-3 rounded-2 fw-regular ${index%2===0 ? 'bg-primary' : 'bg-new-secondary'}`}>
            <div className='d-flex'>
              <div className="">{item.name}</div>
            </div>
          </div>
        </div>
        <div>
          {/* <Link className='p-2 px-3 bg-teal-600 text-white text-xl rounded shadow me-3' to={`/priceList/new/?duplicate=true&duplicateId=${item._id}`}><i className='bi bi-copy'></i></Link> */}
          <button className='p-1 px-3 bg-teal-600 text-white text-xl rounded shadow me-3' onClick={()=>handleDuplicate(item._id)}><i className='bi bi-copy'></i></button>
          <Link className='p-2 px-3 bg-new-warning text-white text-xl rounded shadow me-3' to={`/priceList/${item._id}`}><i className='bi bi-pencil'></i></Link>
          <button className='p-1 px-3 bg-new-danger text-white text-xl rounded shadow' onClick={()=>handleDelete(item._id)}><i className='bi bi-trash'></i></button>
        </div>
      </div>
      ))}
      <button className='btn btn-primary d-none' data-bs-toggle="modal" data-bs-target="#deletePriceList" id='openDeletePriceListModal'>Delete priceList</button>
      <DeletePriceList id={selectedPriceListId} />
      <button className='btn btn-primary d-none' data-bs-toggle="modal" data-bs-target="#duplicateHandler" id='openDuplicateHandlerModal'>Duplicate Handler</button>
      <DuplicateHandler id={selectedPriceListId} />
    </div>
</section>
  )
}

export default PriceList