import React from 'react'
import Modal from "../utility/Modal"
import { useNavigate } from 'react-router-dom'

const DuplicateHandler = ({id}) => {

  const navigate = useNavigate()

  const handelSubmit = (e) => {
    e.preventDefault();
    closeModal()
    navigate(`/priceList/new?duplicate=true&duplicateId=${id}`)
  }

  const closeModal = () => {
    document.getElementById("closeDuplicateHandlerModal").click()
  }


  return (
    <section>
        <Modal targetName={"duplicateHandler"}>
            <div className='p-lg-5 p-1 text-center'>
                <h1>Duplicate Price List</h1>
                <div className="row">
                    <div className="col-12 col-lg-6 mt-5">
                        <button className="btn btn-danger shadow w-100" onClick={closeModal}>Cancel</button>
                    </div>
                    <div className="col-12 col-lg-6 mt-5">
                        <button className="btn btn-primary shadow w-100" onClick={handelSubmit}>Duplicate</button>
                    </div>
                </div>
            </div>
            <button className='d-none' data-bs-dismiss="modal" id="closeDuplicateHandlerModal">Close modal</button>
        </Modal>
    </section>
  )
}

export default DuplicateHandler