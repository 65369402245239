import React from 'react';
import PrintChallanComponent from './PrintChallanComponent';

const PrintChallanPageHandler = ({ challan, challansPerPage }) => {
  const items = challan.items;
  const numberOfComponents = Math.ceil(items.length / challansPerPage);

  const renderPrintComponents = () => {
    const printComponents = [];

    for (let i = 0; i < numberOfComponents; i++) {
      const startIdx = i * challansPerPage;
      const endIdx = (i + 1) * challansPerPage;
      const componentItems = items.slice(startIdx, endIdx);

      // Check if it's the last component
      const isLastComponent = i === numberOfComponents - 1;

      let quantity = 0
      for (let i = 0; i < challan.items.length; i++) {
        const item = challan.items[i];
        quantity = quantity + item.quantity
      }
      const totalQuantity = quantity

      printComponents.push(
        <PrintChallanComponent key={i} challan={{ ...challan, items: componentItems }} showTotal={isLastComponent} startIdx={startIdx} totalQty={totalQuantity} />
      );
    }

    return printComponents;
  };

  return <div>{renderPrintComponents()}</div>;
};

export default PrintChallanPageHandler;
