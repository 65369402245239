import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

function LineChart({ chartData }) {
    const options = {
        responsive: true,
        tension: 0 // 2. Set the tension (curvature) of the line to your liking.  (You may want to lower this a smidge.)
      };
  return <Bar data={chartData} options={options} />;
}

export default LineChart;