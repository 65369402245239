import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { priceListItemsBundler } from '../../utility/products'

const ItemsHandler = ({items, setItems, totalAmount, setTotalAmount, newItemDetails, setNewItemDetails, selectedPriceList}) => {

    const [products, setProducts] = useState([])

    useEffect(() => {
      if (selectedPriceList) {
        setProducts(priceListItemsBundler(selectedPriceList))
      }
    }, [selectedPriceList])
    

      const addNewItemDetailHandler = () => {
        const randomId = Math.random()
        const tempNewItemDetails = { ...newItemDetails, totalAmount: newItemDetails.price * newItemDetails.quantity, randomId: randomId }
        setItems([...items, tempNewItemDetails])
        items.push()
        setNewItemDetails({ name: '', quantity: '', price: '', totalAmount: 0 })

    }

    const handleItemDetailNameChange = (e, item) => {
        setItems(items.map((i) => (
          i.randomId === item.randomId
            ? { ...item, name: e.target.value }
            : i
        )))
      }
    
      const handleItemDetailQuantityChange = (e, item) => {
        setItems(items.map((i) => (
          i.randomId === item.randomId
            ? { ...item, quantity: e.target.value, totalAmount: item.price * e.target.value }
            : i
        )))
        }
        
          const handleItemDetailPriceChange = (e, item) => {
        setItems(items.map((i) => (
          i.randomId === item.randomId
            ? { ...item, price: e.target.value, totalAmount: e.target.value * item.quantity }
            : i
        )))
        }
    
        const handleAutoComplete = (e, detials, type) => {
          if (type==="onChange") {
            // onChange means that the value is being selected by clicking
            setNewItemDetails({...newItemDetails, name: detials.name, price: detials.price})
          } else {
            setNewItemDetails({...newItemDetails, name: detials})
          }
        }

        // Clear Btn Handler
        setTimeout(async () => {
            const nameAutocomplete = document.querySelector(".autocomplete-product");
            const clearIndicator = nameAutocomplete.querySelector(".MuiAutocomplete-clearIndicator");
            clearIndicator.addEventListener("click", () => {
                setNewItemDetails({...newItemDetails, name: "", price: ''})
            });
        }, 100);

        useEffect(() => {
            if (items.length>0) {
                let amount = 0
                for (let i = 0; i < items.length; i++) {
                  const itemTotalAmount = items[i].totalAmount;
                  amount = amount + itemTotalAmount
                }
                setTotalAmount(amount)
            }
          }, [items])

  return (
    <>
        {items.map((item, index) => (
        <div key={index} className="row mb-4">
            <div className="col-sm-12 col-lg-6">
                <TextField label="Item Name" variant="outlined" type="text" className="form-control shadow bg-success bg-opacity-20 mt-4"
                value={item.name}
                onChange={(e)=>handleItemDetailNameChange(e, item)}
                />
            </div>
            <div className="col-sm-12 col-lg-6 row mx-0 px-0">
                <div className="col">
                <TextField label="Qty" variant="outlined" type="number" className="form-control shadow bg-success bg-opacity-20 mt-4"
                value={item.quantity}
                onChange={(e)=>handleItemDetailQuantityChange(e, item)}
                />
                </div>
                <div className="col">
                <TextField label="Price" variant="outlined" type="number" className="form-control shadow bg-success bg-opacity-20 mt-4"
                value={item.price}
                onChange={(e)=>handleItemDetailPriceChange(e, item)}
                />
                </div>
            <div className="col">
                <TextField label="Amount" variant="outlined" type="number" className="form-control bg-dark bg-opacity-20 shadow mt-4"
                value={item.quantity * item.price}
                disabled
                />
                </div>
            </div>
        </div>
        ))}
        {/* New Item handler */}
        <div>
        <div className="row py-2">
            <div className="col-sm-12 col-lg-6">
                <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={products}
                className="shadow bg-danger bg-opacity-20 mt-4 autocomplete-product"
                autoHighlight
                freeSolo
                getOptionLabel={(option) => option.name.toUpperCase()}
                renderInput={(params) => <TextField {...params} label="Item Name" />}
                // value={newItemDetails.name}
                inputValue={newItemDetails.name}
                // This is the display value
                onInputChange={(event, newInputValue) => {
                    handleAutoComplete(event, newInputValue, "onInputChange");
                }}
                // This is the actual value
                onChange={(event, newValue) => {
                    handleAutoComplete(event, newValue, "onChange");
                }}
                />
            </div>
            <div className="col-sm-12 col-lg-6 row mx-0 px-0">
                <div className="col">
                <TextField label="Qty" variant="outlined" type="number" className="form-control shadow bg-danger bg-opacity-20 mt-4"
                value={newItemDetails.quantity}
                onChange={(e)=>setNewItemDetails({...newItemDetails, quantity: e.target.value})}
                />
                </div>
                <div className="col">
                <TextField label="Price" variant="outlined" type="number" className="form-control shadow bg-danger bg-opacity-20 mt-4"
                value={newItemDetails.price}
                onChange={(e)=>setNewItemDetails({...newItemDetails, price: e.target.value})}
                />
                </div>
            <div className="col">
                <TextField label="Amount" variant="outlined" type="number" className="form-control bg-dark bg-opacity-20 shadow mt-4"
                value={newItemDetails.quantity * newItemDetails.price}
                disabled
                />
                </div>
            </div>
        </div>
        <button className='btn btn-danger mt-4' onClick={addNewItemDetailHandler} disabled={!newItemDetails.name}>Save Item</button>
        </div>
        <div className="row">
            <div className="col-sm-12 col-lg-6"></div>
            <div className="col-sm-12 col-lg-6 row mx-0 px-0">
                <div className="col-3 col-lg-4"></div>
                <div className="col-3 col-lg-4"></div>
            <div className="col-6 col-lg-4">
                <label className='mt-4 text-lg-start text-end w-100'>Total</label>
                <input type="text" className="form-control bg-dark bg-opacity-20 shadow text-lg-start text-end"
                value={totalAmount}
                disabled
                />
                </div>
            </div>
        </div>
    </>
  )
}

export default ItemsHandler