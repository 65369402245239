import axios from "axios";
import { API } from "../../Backend";
import {
    ALL_PRICELIST_FAIL,
    ALL_PRICELIST_REQUEST,
    ALL_PRICELIST_SUCCESS,
    CREATE_PRICELIST_FAIL,
    CREATE_PRICELIST_REQUEST,
    CREATE_PRICELIST_SUCCESS,
    PRICELIST_DETAILS_REQUEST,
    PRICELIST_DETAILS_SUCCESS,
    PRICELIST_DETAILS_FAIL,
    DELETE_PRICELIST_FAIL,
    DELETE_PRICELIST_REQUEST,
    DELETE_PRICELIST_SUCCESS,
    UPDATE_PRICELIST_FAIL,
    UPDATE_PRICELIST_REQUEST,
    UPDATE_PRICELIST_SUCCESS,
    CLEAR_ERRORS
} from "../constants/priceListConstant"

export const getPriceLists = () => async(dispatch) => {
    try {
        dispatch({ type: ALL_PRICELIST_REQUEST })

        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }
        
        const { data } = await axios.get(`${API}/priceList/get`, config)
        dispatch({type: ALL_PRICELIST_SUCCESS, payload: data})

    } catch (error) {
        dispatch({
            type: ALL_PRICELIST_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getPriceListDetails = (id) => async(dispatch) => {
    try {
        dispatch({ type: PRICELIST_DETAILS_REQUEST })
        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }
        
        const {data} = await axios.get(`${API}/priceList/getSingle/${id}`, config)
        dispatch({type: PRICELIST_DETAILS_SUCCESS, payload: data.priceList})
        return data
    } catch (error) {
        dispatch({
            type: PRICELIST_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const createPriceList = (details) => async(dispatch) => {
    try {
        dispatch({type: CREATE_PRICELIST_REQUEST})
        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }
        const {data} = await axios.post(`${API}/priceList/post`, details, config)
        dispatch({type: CREATE_PRICELIST_SUCCESS, payload: data})
        return data

    } catch (error) {
        dispatch({
            type: CREATE_PRICELIST_FAIL,
            payload: error.response.data.message
        })
        return error.response.data
    }
}


export const deletePriceList = (id) => async(dispatch) => {
    try {
        dispatch({type: DELETE_PRICELIST_REQUEST})
        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }
        const { data } = await axios.delete(`${API}/priceList/delete/${id}`, config)
        dispatch({type: DELETE_PRICELIST_SUCCESS, payload: id})
        return data

    } catch (error) {
        dispatch({
            type: DELETE_PRICELIST_FAIL,
            payload: error.response.data.message
        })
        return error.response.data
    }
}

export const updatePriceList = (id, details) => async(dispatch) => {
    try {
        dispatch({type: UPDATE_PRICELIST_REQUEST})
        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }
        const {data} = await axios.put(`${API}/priceList/put/${id}`, details, config)
        dispatch({type: UPDATE_PRICELIST_SUCCESS, payload: data})
        return data

    } catch (error) {
        dispatch({
            type: UPDATE_PRICELIST_FAIL,
            payload: error.response.data.message
        })
        return error.response.data
    }
}


// Used for cleaning errors
export const clearErrors = () => async(dispatch) => {
    dispatch({type: CLEAR_ERRORS})
}