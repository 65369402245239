export const ALL_PRICELIST_REQUEST = "ALL_PRICELIST_REQUEST"
export const ALL_PRICELIST_SUCCESS = "ALL_PRICELIST_SUCCESS"
export const ALL_PRICELIST_FAIL = "ALL_PRICELIST_FAIL"
export const PRICELIST_DETAILS_REQUEST = "PRICELIST_DETAILS_REQUEST"
export const PRICELIST_DETAILS_SUCCESS = "PRICELIST_DETAILS_SUCCESS"
export const PRICELIST_DETAILS_FAIL = "PRICELIST_DETAILS_FAIL"
export const CREATE_PRICELIST_REQUEST = "CREATE_PRICELIST_REQUEST"
export const CREATE_PRICELIST_SUCCESS = "CREATE_PRICELIST_SUCCESS"
export const CREATE_PRICELIST_FAIL = "CREATE_PRICELIST_FAIL"
export const DELETE_PRICELIST_REQUEST = "DELETE_PRICELIST_REQUEST"
export const DELETE_PRICELIST_SUCCESS = "DELETE_PRICELIST_SUCCESS"
export const DELETE_PRICELIST_FAIL = "DELETE_PRICELIST_FAIL"
export const UPDATE_PRICELIST_REQUEST = "UPDATE_PRICELIST_REQUEST"
export const UPDATE_PRICELIST_SUCCESS = "UPDATE_PRICELIST_SUCCESS"
export const UPDATE_PRICELIST_FAIL = "UPDATE_PRICELIST_FAIL"
export const CLEAR_ERRORS = "CLEAR_ERRORS"