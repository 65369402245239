import Navbar from '../components/utility/Navbar'

const Layout = ({ children }) => {
  
  return (
  <section className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary" id='top'>
    <div className="flex-lg-1 h-screen overflow-y-lg-auto">
      <Navbar/>
      <main className='py-6 bg-surface-secondary'>
        <div className="container">
          {children}
        </div>
      </main>
    </div>
  </section>
  )
}

export default Layout