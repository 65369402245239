import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getPriceListDetails } from '../../redux/actions/priceListAction'
import Loader from '../../utility/Loader'
import ItemsHandler from '../../components/PriceList/ItemsHandler'

const EditPriceList = () => {

    const { id } = useParams()
    const dispatch = useDispatch()
    const {priceLists} = useSelector(state=>state.priceLists)

    const [priceList, setPriceList] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const isPriceListExist = priceLists.find((i) => i._id === id)
        if (isPriceListExist) {
            setPriceList(isPriceListExist)
            setLoading(false)
        } else {
            dispatch(getPriceListDetails(id)).then((res) => {
                if (res.success) {
                    setPriceList(res.priceList)
                    setLoading(false)
                }
            })
        }
    }, [])

  return loading ? <Loader/> : (priceList && <ItemsHandler priceList={priceList} />) 
}

export default EditPriceList

