import React, { useEffect, useState } from 'react'
import moment from 'moment/moment'

const PrintChallanComponent = ({challan, display, showTotal, startIdx, totalQty}) => {
    

  return (
    <div className={`${!display && "display-only-noPrint"}`}>
      <section className="print-section overflow-hidden">
        {/* Admin */}
        <div className="print-div">
          <hr className='my-0' style={{color:"black"}} />
          <span className='bg-dark text-white pt-1 p-2 text-sm'>Admin</span> - Estimate
              <div className="row mt-5">
                  {challan && 
                      <div>
                          <div className="d-flex align-items-center justify-content-between">
                              <h6 className='text-black'>Chalan No. - {challan.challanNumber} | {challan.note}</h6>
                              <h6 className='text-black'>{moment(challan.createdAt).format('D MMM YY')}</h6>
                          </div>
                          <h6>{challan.name} - {challan.address}</h6>
                          <table className="table table-striped mt-2">
                              <thead>
                                  <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">Item Name</th>
                                      <th scope="col">Qty</th>
                                      <th scope="col">Rate</th>
                                      <th scope="col">Amount</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {challan.items && challan.items.map ((item, index)=>(
                                      <tr key={index}>
                                          <th scope="row">{startIdx + index + 1}</th>
                                          <td>{item.name}</td>
                                          <td>{item.quantity}</td>
                                          <td>{item.price}</td>
                                          <td>{item.quantity * item.price}</td>
                                      </tr>
                                  ))}
                              </tbody>
                              { showTotal &&
                                <tfoot>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col">{totalQty}</th>
                                        <th scope="col"></th>
                                        <th scope="col">₹{challan.totalAmount}</th>
                                    </tr>
                                </tfoot>
                              }
                          </table>
                          <div className="d-flex align-items-center justify-content-start flex-wrap gap-5 mt-4">
                              {challan.transporterName && <div>
                                  <h6>Transporter Name</h6>
                                  <p className='text-xs'>{challan.transporterName}</p>
                              </div>}
                              {challan.marka && <div>
                                  <h6>Marka</h6>
                                  <p className='text-xs'>{challan.marka}</p>
                              </div>}
                              {challan.nag && <div>
                                  <h6>Nag</h6>
                                  <p className='text-xs'>{challan.nag}</p>
                              </div>}
                          </div>
                      </div>
                  }
              </div>
        </div>
        {/* Buyer */}
        <div className="print-div">
          <hr className='my-0' style={{color:"black"}} />
          <span className='bg-dark text-white pt-1 p-2 text-sm'>Buyer</span>
          <div className="row mt-5">
                  {challan && 
                      <div>
                          <div className="d-flex align-items-center justify-content-between">
                              <h6 className='text-black'>Chalan No. - {challan.challanNumber} | {challan.note}</h6>
                              <h6 className='text-black'>{moment(challan.createdAt).format('D MMM YY')}</h6>
                          </div>
                          <h6>{challan.name} - {challan.address}</h6>
                          <table className="table table-striped mt-2">
                              <thead>
                                  <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">Item Name</th>
                                      <th scope="col">Qty</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {challan.items && challan.items.map ((item, index)=>(
                                      <tr  key={index}>
                                          <th scope="row">{startIdx + index + 1}</th>
                                          <td>{item.name}</td>
                                          <td>{item.quantity}</td>
                                      </tr>
                                  ))}
                              </tbody>
                              { showTotal &&
                                <tfoot>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col">{totalQty}</th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </tfoot>
                              }
                          </table>
                          <div className="d-flex align-items-center justify-content-start flex-wrap gap-5 mt-4">
                              {challan.transporterName && <div>
                                  <h6>Transporter Name</h6>
                                  <p className='text-xs'>{challan.transporterName}</p>
                              </div>}
                              {challan.marka && <div>
                                  <h6>Marka</h6>
                                  <p className='text-xs'>{challan.marka}</p>
                              </div>}
                              {challan.nag && <div>
                                  <h6>Nag</h6>
                                  <p className='text-xs'>{challan.nag}</p>
                              </div>}
                          </div>
                      </div>
                  }
              </div>       
        </div>
        {/* Buyer */}
        <div className="print-div">
          <hr className='my-0' style={{color:"black"}} />
          <span className='bg-dark text-white pt-1 p-2 text-sm'>Buyer</span>
          <div className="row mt-5">
                  {challan && 
                      <div>
                          <div className="d-flex align-items-center justify-content-between">
                              <h6 className='text-black'>Chalan No. - {challan.challanNumber} | {challan.note}</h6>
                              <h6 className='text-black'>{moment(challan.createdAt).format('D MMM YY')}</h6>
                          </div>
                          <h6>{challan.name} - {challan.address}</h6>
                          <table className="table table-striped mt-2">
                              <thead>
                                  <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">Item Name</th>
                                      <th scope="col">Qty</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {challan.items && challan.items.map ((item, index)=>(
                                      <tr  key={index}>
                                          <th scope="row">{startIdx + index + 1}</th>
                                          <td>{item.name}</td>
                                          <td>{item.quantity}</td>
                                      </tr>
                                  ))}
                              </tbody>
                              { showTotal &&
                                <tfoot>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col">{totalQty}</th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </tfoot>
                              }
                          </table>
                          <div className="d-flex align-items-center justify-content-start flex-wrap gap-5 mt-4">
                              {challan.transporterName && <div>
                                  <h6>Transporter Name</h6>
                                  <p className='text-xs'>{challan.transporterName}</p>
                              </div>}
                              {challan.marka && <div>
                                  <h6>Marka</h6>
                                  <p className='text-xs'>{challan.marka}</p>
                              </div>}
                              {challan.nag && <div>
                                  <h6>Nag</h6>
                                  <p className='text-xs'>{challan.nag}</p>
                              </div>}
                          </div>
                      </div>
                  }
              </div>       
        </div>
        {/* Transporter */}
        {showTotal && 
            <div className="print-div">
            <hr className='my-0' style={{color:"black"}} />
            <span className='bg-dark text-white pt-1 p-2 text-sm'>Transporter</span>
            <div className="row mt-5">
                    {challan && 
                        <div>
                            <h3>{moment(challan.createdAt).format('D MMM YY')}</h3>
                            <h3 className='mb-4'>{challan.note}</h3>
                            <h3>{challan.name} - {challan.address}</h3>
                            <div className="d-flex align-items-center justify-content-start flex-wrap gap-5 mt-4">
                                {challan.transporterName && <div>
                                    <p>Transporter Name</p>
                                    <h3 className='text-lg'>{challan.transporterName}</h3>
                                </div>}
                                {challan.marka && <div>
                                    <p>Marka</p>
                                    <h3 className='text-lg'>{challan.marka}</h3>
                                </div>}
                                {challan.nag && <div>
                                    <p>Nag</p>
                                    <h3 className='text-lg'>{challan.nag}</h3>
                                </div>}
                            </div>
                        </div>
                    }
                </div>       
          </div>
        }
      </section>
    </div>
  )
}

PrintChallanComponent.defaultProps = {
    display: false,
    showTotal: false,
    startIdx: 0,
    totalQty: 0
}

export default PrintChallanComponent