import axios from "axios";
import { API } from "../../Backend";
import {
    TOP_ITEMS_SOLD_FAIL,
    TOP_ITEMS_SOLD_REQUEST,
    TOP_ITEMS_SOLD_SUCCESS,
    TOP_CUSTOMERS_FAIL,
    TOP_CUSTOMERS_REQUEST,
    TOP_CUSTOMERS_SUCCESS,
    TOTAL_SALES_FAIL,
    TOTAL_SALES_REQUEST,
    TOTAL_SALES_SUCCESS,
    FILTER_CHALLANS_FAIL,
    FILTER_CHALLANS_REQUEST,
    FILTER_CHALLANS_SUCCESS,
    TOTAL_TAX_SALES_FAIL,
    TOTAL_TAX_SALES_REQUEST,
    TOTAL_TAX_SALES_SUCCESS,
    GET_CUSTOMERS_FAIL,
    GET_CUSTOMERS_REQUEST,
    GET_CUSTOMERS_SUCCESS,
    CLEAR_ERRORS
} from "../constants/analyticsConstant"

export const getTopItemsSold = (filters) => async(dispatch) => {
    try {
        dispatch({ type: TOP_ITEMS_SOLD_REQUEST })

        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }

        const apiUrl = `${API}/analytics/topItemsSold${
            filters.from ? `?from=${filters.from}` : ''
          }${filters.to ? `${filters.from ? '&' : '?'}to=${filters.to}` : ''}${
            filters.name ? `${filters.from || filters.to ? '&' : '?'}name=${filters.name}` : ''
          }`;

        const { data } = await axios.get(apiUrl, config)
        dispatch({type: TOP_ITEMS_SOLD_SUCCESS, payload: data})

    } catch (error) {
        dispatch({
            type: TOP_ITEMS_SOLD_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getTotalSales = (filters) => async(dispatch) => {
    try {
        dispatch({ type: TOTAL_SALES_REQUEST })

        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }

        const apiUrl = `${API}/analytics/totalSales${filters.from ? `?from=${filters.from}` : ''}${filters.to ? `&to=${filters.to}` : ''}`;
        
        const { data } = await axios.get(apiUrl, config)
        dispatch({type: TOTAL_SALES_SUCCESS, payload: data})

    } catch (error) {
        dispatch({
            type: TOTAL_SALES_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getTopCustomers = (filters) => async(dispatch) => {
    try {
        dispatch({ type: TOP_CUSTOMERS_REQUEST })

        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }

        const apiUrl = `${API}/analytics/topCustomers${
            filters.from ? `?from=${filters.from}` : ''
          }${filters.to ? `${filters.from ? '&' : '?'}to=${filters.to}` : ''}${
            filters.name ? `${filters.from || filters.to ? '&' : '?'}name=${filters.name}` : ''
          }`;
          
        
        const { data } = await axios.get(apiUrl, config)
        dispatch({type: TOP_CUSTOMERS_SUCCESS, payload: data})

    } catch (error) {
        dispatch({
            type: TOP_CUSTOMERS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getFilterChallans = (filters) => async(dispatch) => {
    try {
        console.log(filters)
        dispatch({ type: FILTER_CHALLANS_REQUEST })

        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }

        
        const apiUrl = `${API}/analytics/filterChallans${
            filters.from ? `?from=${filters.from}` : ''
        }${filters.to ? `${filters.from ? '&' : '?'}to=${filters.to}` : ''}${
            filters.name ? `${filters.from || filters.to ? '&' : '?'}name=${filters.name}` : ''
        }${filters.exactNameCheck ? `${(filters.from || filters.to || filters.name) ? '&' : '?'}exactNameCheck=${filters.exactNameCheck}` : ''}`;
        
          
        
        const { data } = await axios.get(apiUrl, config)
        dispatch({type: FILTER_CHALLANS_SUCCESS, payload: data})

    } catch (error) {
        dispatch({
            type: FILTER_CHALLANS_FAIL,
            payload: error.response.data.message
        })
    }
}


export const getTaxSales = (filters) => async(dispatch) => {
    try {
        dispatch({ type: TOTAL_TAX_SALES_REQUEST })

        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }

        const apiUrl = `${API}/analytics/taxSales${filters.from ? `?from=${filters.from}` : ''}${filters.to ? `&to=${filters.to}` : ''}`;
        
        const { data } = await axios.get(apiUrl, config)
        dispatch({type: TOTAL_TAX_SALES_SUCCESS, payload: data})

    } catch (error) {
        dispatch({
            type: TOTAL_TAX_SALES_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getCustomers = () => async(dispatch) => {
    try {
        dispatch({ type: GET_CUSTOMERS_REQUEST })

        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }

        const apiUrl = `${API}/analytics/getCustomers`;
        
        const { data } = await axios.get(apiUrl, config)
        dispatch({type: GET_CUSTOMERS_SUCCESS, payload: data})

    } catch (error) {
        dispatch({
            type: GET_CUSTOMERS_FAIL,
            payload: error.response.data.message
        })
    }
}


// Used for cleaning errors
export const clearErrors = () => async(dispatch) => {
    dispatch({type: CLEAR_ERRORS})
}