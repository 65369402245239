import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateBill } from '../../redux/actions/billAction'
import HeadingAndDate from '../../components/BillingSystem/HeadingAndDate'
import BillToSupplyTo from '../../components/BillingSystem/BillToSupplyTo'
import ItemsHandler from '../../components/BillingSystem/ItemsHandler'
import SaveAndTotal from '../../components/BillingSystem/SaveAndTotal'
import { useNavigate, useParams } from 'react-router-dom'

const EditBill = () => {

  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {bills} = useSelector(state=>state.bills)
  const {priceLists} = useSelector(state=>state.priceLists)


  const [buttonLoading, setButtonLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false);

  // const [billDetails, setBillDetails] = useState()

  const [date, setDate] = useState(moment())
  const [items, setItems] = useState([])
  const [newItemDetails, setNewItemDetails] = useState({ name: '', quantity: '', price: '', totalAmount: 0 })
  const [billTo, setBillTo] = useState({name:"", gst: "", address: "", state: "", stateCode: ""})
  const [placeOfSupply, setPlaceOfSupply] = useState({name:"", gst: "", address: "", state: "", stateCode: ""})
  const [modeOfTransport, setModeOfTransport] = useState({mode: "", vehicleNo: ""})
  const [subTotalAmount, setSubTotalAmount] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [totalAmount, setTotalAmount] = useState()
  const [taxCategory, setTaxCategory] = useState("IN");
  const [taxPercentage, setTaxPercentage] = useState(18)
  const [sameAsBillTo, setSameAsBillTo] = useState(true);
  const [selectedPriceList, setSelectedPriceList] = useState()

  useEffect(() => {
    const isBillExist = bills.find((i) => i._id === id)
    if (isBillExist) {
      setBillDetails(isBillExist)
    }
  }, [])
  
  const setBillDetails = (details) => {
    setDate(details.createdAt)
    setItems(details.items)
    setBillTo(details.billTo)
    setPlaceOfSupply(details.placeOfSupply)
    setModeOfTransport(details.modeOfTransport)
    setSubTotalAmount(details.subTotalAmount)
    setDiscount(details.discount)
    setTotalAmount(details.totalAmount)
    setTaxCategory(details.taxCategory)
    setTaxPercentage(details.taxPercentage)
    setSameAsBillTo(checkforSameAsBillTo(details))
    setSelectedPriceList(priceLists.find((i) => i.name === details.priceList))
    setLoading(false)
  }
  
  const checkforSameAsBillTo = (details) => {
    let same = false
    if (details.billTo.name === details.placeOfSupply.name && details.billTo.address === details.placeOfSupply.address && details.billTo.gst === details.placeOfSupply.gst && details.billTo.state === details.placeOfSupply.state && details.billTo.stateCode === details.placeOfSupply.stateCode) {
      same = true
    } else {
      same = false
    }
    return same
  }

  useEffect(() => {
    // Updating Sub Total Amt
    if (items.length>0) {
        let amount = 0
        for (let i = 0; i < items.length; i++) {
          const itemTotalAmount = items[i].quantity * items[i].price;
          amount = amount + itemTotalAmount
        }
        setSubTotalAmount(amount)
    }
    // Updating Tax Percentage
    if (items.length>0) {
      const isTax28 = items[0].hsn && items[0].hsn.toString() === '8708'
      const isTax18 = items[0].hsn && items[0].hsn.toString() === '7320'
      setTaxPercentage(isTax28 ? 28 : isTax18 ? 18 : taxPercentage)
    }
  }, [items])
  
  const handleSubmit = (action) => {
    if (!billTo.name) {
      setError("Plase fill Bill To Details atleast")
      return
    }
    setButtonLoading(true)
    const data = {
      billTo: billTo,
      placeOfSupply: sameAsBillTo ? billTo : placeOfSupply,
      modeOfTransport: modeOfTransport,
      items: items,
      subTotalAmount: subTotalAmount,
      discount: discount,
      taxPercentage: taxPercentage,
      taxCategory: taxCategory,
      taxableAmount: subTotalAmount - (subTotalAmount * discount/100),
      totalTax: (subTotalAmount - (subTotalAmount * discount/100)) *taxPercentage/100,
      totalAmount: Math.round(totalAmount),
      priceList: selectedPriceList ? selectedPriceList.name : 'Pai Spice' ,
      createdAt: JSON.stringify(date).substring(1, JSON.stringify(date).length -1)
    }
    // console.log(data)
    // setButtonLoading(false)
    dispatch(updateBill(id, data))
    .then(res => {
      if (res.success) {
        action === "saveAndPrint" ? navigate(`/bill/print/${res.bill._id}?print=true`) : navigate('/bills')
      } else {
        setError(res.message)
      }
      setButtonLoading(false)
    })
  }


  return (
    <section className='py-5 billing-system'>
      <HeadingAndDate date={date} setDate={setDate} mode={"Edit"} />
      <BillToSupplyTo billTo={billTo} setBillTo={setBillTo} placeOfSupply={placeOfSupply} setPlaceOfSupply={setPlaceOfSupply} sameAsBillTo={sameAsBillTo} setSameAsBillTo={setSameAsBillTo} modeOfTransprot={modeOfTransport} setModeOfTransport={setModeOfTransport} selectedPriceList={selectedPriceList} setSelectedPriceList={setSelectedPriceList} />
      <ItemsHandler items={items} setItems={setItems} newItemDetails={newItemDetails} setNewItemDetails={setNewItemDetails} selectedPriceList={selectedPriceList} />
      <SaveAndTotal taxCategory={taxCategory} setTaxCategory={setTaxCategory} subTotalAmount={subTotalAmount} totalAmount={totalAmount} setTotalAmount={setTotalAmount} buttonLoading={buttonLoading} handleSubmit={handleSubmit} discount={discount} setDiscount={setDiscount} taxPercentage={taxPercentage} setTaxPercentage={setTaxPercentage} error={error} stateCode={billTo.stateCode} newItemDetails={newItemDetails} />
    </section>
  )
}

export default EditBill