import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTaxSales } from '../../redux/actions/analyticsAction'
import Loader from '../../utility/Loader'
import moment from 'moment'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers'

import LineChart from '../../components/charts/LineChart';


const TaxSales = () => {

    const numWords = require('num-words')

    const dispatch = useDispatch();
    const { totalTaxSalesData, totalTaxSalesAmount, totalTaxAmount, loading } = useSelector((state) => state.analytics);
    
    const [from, setFrom] = useState(window.sessionStorage.getItem("from") ? moment(window.sessionStorage.getItem("from")).startOf('day') : moment().subtract(1, 'months').startOf('day'))
    const [to, setTo] = useState(window.sessionStorage.getItem("to") ? moment(window.sessionStorage.getItem("to")).startOf('day') : moment().endOf('day'));
    const [filters, setFilters] = useState({ from, to });   
  
    useEffect(() => {
      if (totalTaxSalesData.length === 0) {
        dispatch(getTaxSales(filters));
      }
    }, [filters]);

      const submit = () => {
        dispatch(getTaxSales(filters));
        console.log(filters)
      }

      const setNewChartData = () => {
        const newData = {
            labels: totalTaxSalesData.map((data) => moment(data.date).format('D MMM YYYY')),
            datasets: [{
              label: "Sales in INR",
              data: totalTaxSalesData.map((data) => data.totalTaxSales),
              backgroundColor: '#116D6E',
            }]
        }

        return newData
      }

      const [chartData, setChartData] = useState(setNewChartData())


      useEffect(() => {
        if (totalTaxSalesData.length !== 0) {
          setChartData(setNewChartData())
        }
      }, [totalTaxSalesData]);

      const handleDateChange = (type, value) => {
        if (type === "from") {
          setFrom(value)
        } else {
          setTo(value)
        }
        window.sessionStorage.setItem(type, value);
        setFilters({ ...filters, [type]: value });
      }
  
      const inrFormatter = (value) =>
      new Intl.NumberFormat('en-IN').format(value);
  
      
    const handlePrint = () => {
      window.print();
    };
  

  return loading ? (<Loader/>) : (
    <section className="my-5 container-fluid">
        <div className="pb-5"><h2 className="text-primary text-center">Total Sales</h2></div>
        {/* Filters */}
        <div className="d-flex gap-5 flex-wrap">
        <div className="position-relative d-flex mr-3">
                <span>From - </span>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-gb'}>
                    <MobileDatePicker
                    value={from}
                    onChange={(newValue) => {
                        handleDateChange("from", newValue)
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                        <>
                        <p className="text-primary text-underline fw-bold cursor-pointer" ref={inputRef} {...inputProps}>
                            {moment(from).startOf('day').format('D MMM YYYY')}
                        </p>
                        {InputProps?.endAdornment}
                        </>
                    )}
                    />
                </LocalizationProvider>
                </div>
                <div className="position-relative d-flex">
                <span>To - </span>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-gb'}>
                    <MobileDatePicker
                    value={to}
                    onChange={(newValue) => {
                        handleDateChange("to", newValue)
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                        <>
                        <p className="text-primary text-underline fw-bold cursor-pointer" ref={inputRef} {...inputProps}>
                            {moment(to).endOf('day').format('D MMM YYYY')}
                        </p>
                        {InputProps?.endAdornment}
                        </>
                    )}
                    />
                </LocalizationProvider>
                </div>
            <button className='btn btn-sm btn-primary' onClick={submit}>Submit</button>
        </div>
        <button className='btn btn-sm btn-success my-4 d-print-none' onClick={handlePrint}>Print</button>
        <div className='mt-5'>
            <h3 style={{color:"#116D6E"}}>Total Sales - ₹ {inrFormatter(totalTaxSalesAmount)}</h3>
            <h3 className='text-capitalize mb-5' style={{color:"#116D6E"}}>{numWords(Math.trunc(totalTaxSalesAmount))}</h3>
            <h3 className='text-danger'>Total Tax - ₹ {inrFormatter(totalTaxAmount)}</h3>
            <h3 className='text-danger text-capitalize mb-5'>{numWords(Math.trunc(totalTaxAmount))}</h3>
            <LineChart chartData={chartData} />
        </div>
    </section>
  )
}

export default TaxSales