import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import html2canvas from 'html2canvas'

const PrintHandler = ({details, type, copyType}) => {

  const numWords = require('num-words')

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    document.title = `${type==="bill" ? "Tax" : "PI"}-${type==="bill" ? details.taxNumber : details.invoiceNumber} | ${details.billTo.name}`; 
  }, [])

  const [discountAmount, setDiscountAmount] = useState(details.subTotalAmount * details.discount/100)
  const [taxableAmount, setTaxableAmount] = useState(details.subTotalAmount - (details.subTotalAmount * details.discount/100))

  useEffect(() => {
    const discountAmt = details.subTotalAmount * details.discount/100
    const taxableAmt = details.subTotalAmount - (details.subTotalAmount * details.discount/100)
    setDiscountAmount(discountAmt)
    setTaxableAmount(taxableAmt)
  }, [])

  const handleDownloadImage = async () => {
    console.log("Running Download Image")
    const element = document.querySelector('.bill-content'),
    canvas = await html2canvas(element),
    data = canvas.toDataURL('image/jpg'),
    link = document.createElement('a');

    link.href = data;
    link.download = `${type==="bill" ? "Tax" : "PI"}-${type==="bill" ? details.taxNumber : details.invoiceNumber} | ${details.billTo.name}`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <div className={`${copyType !== 'Original Copy' && 'display-only-noPrint'}`}>
        <nav className={`navbar navbar-light position-lg-sticky top-lg-0 overlap-10 flex-none bg-primary border-bottom px-0 py-3 d-print-none ${copyType !== 'Original Copy' && 'd-none'}`}>
            <div className="container-fluid d-flex align-items-center justify-content-between">
                <Link to='/' className='text-white h6 fw-bold'>Omtech Auto</Link>
                <div className='d-flex flex-wrap gap-2 mt-3 mt-lg-0'>
                  <div><span onClick={handleDownloadImage} className="p-2 px-4 bg-new-danger rounded text-white text-l fw-semibold cursor-pointer text-capitalize"><i className="bi bi-image"></i> Download Image</span></div>
                  <div><span onClick={handlePrint} className="p-2 px-4 bg-new-success rounded text-white text-l fw-semibold cursor-pointer text-capitalize"><i className="bi bi-printer"></i> Print {type}</span></div>
                </div>
            </div>
        </nav>
        <section className={`print-section-billing`} data-bs-theme={type==="bill" ? "bill" : "performa"}>
            <div className='bill-content'>
                {/* Header */}
                <p className="text-end fw-light" style={{fontSize:"14px"}}><i>{copyType}</i></p>
                <div className="invoice-header">
                    {/* LOGO */}
                    <div className='logo-div'>
                        <h1 className='fw-bold text-dark mb-0'>{type==="bill" ? "Tax" : "Performa"} Invoice</h1>
                        {type === "bill" ? <img src="/assets/img/logos/logo-bill.png" alt="" /> : <img src="/assets/img/logos/logo-bill.png" alt="" />}
                    </div>
                    {/* Contact Details */}
                    <div className='contact-details'>
                        <h1 className='fw-bold text-dark mb-0'>Omtech Auto</h1>
                        <div className='contact-details'>
                            <p><strong>GSTIN No.:</strong>07AEGPA1899B1ZS</p>
                            <div className='flex-between gap-2'>
                                <p>+91 9312254728 <img src={`/assets/img/icons/${type==='bill' ? 'bill' : 'pi'}/phone.svg`} alt="" /></p>
                                <p>+91 9312254728 <img src={`/assets/img/icons/${type==='bill' ? 'bill' : 'pi'}/whatsapp.svg`} alt="" /></p>
                            </div>
                            <p>omtechauto70@gmail.com <img src={`/assets/img/icons/${type==='bill' ? 'bill' : 'pi'}/email.svg`} alt="" /></p>
                            <p>https://www.omtechauto.in/ <img src={`/assets/img/icons/${type==='bill' ? 'bill' : 'pi'}/website.svg`} alt="" /></p>
                            <p>726, Near Govt School, Burari, New Delhi- 110084 <img src={`/assets/img/icons/${type==='bill' ? 'bill' : 'pi'}/address.svg`} alt="" /></p>
                        </div>
                    </div>
                </div>
                {/* Bill To - Place Of Supply - Invoice No. and Date */}
                <div className="bill-to">
                    <div>
                        <p className="fw-bold">Bill To</p>
                        <p>{details.billTo.name}</p>
                        <p>{details.billTo.address}</p>
                        <p>State - {details.billTo.state} ({details.billTo.stateCode})</p>
                        <p>GSTIN - {details.billTo.gst}</p>
                    </div>
                    <div>
                        <p className="fw-bold">Ship To</p>
                        <p>{details.placeOfSupply.name}</p>
                        <p>{details.placeOfSupply.address}</p>
                        <p>State - {details.placeOfSupply.state} ({details.placeOfSupply.stateCode})</p>
                        <p>GSTIN - {details.placeOfSupply.gst}</p>
                    </div>
                    <div className="invoice-number-div">
                        <p className="fw-bold">{type==="bill" ? `Tax No. - ${String(details.taxNumber).padStart(3, '0')}` : `Invoice No. - ${details.invoiceNumber}`}</p>
                        <p>{moment(details.createdAt).format('D MMM YYYY')}</p>
                    </div>
                </div>
                {(details.modeOfTransport.mode || details.modeOfTransport.vehicleNo) && <hr className='my-2' />}
                <div className='mode-of-transport'>
                  {details.modeOfTransport.mode && <p>Mode Of Transport - {details.modeOfTransport.mode}</p>}
                  {details.modeOfTransport.vehicleNo && <p>Vehicle No. - {details.modeOfTransport.vehicleNo}</p>}
                </div>
                {/* Items */}
                <div className="item-header">
                    <span className='item-header-s-no'>S.No</span>
                    <span className='item-header-name'>Name</span>
                    <span className='item-header-hsn'>HSN</span>
                    <span className='item-header-qty'>Qty</span>
                    <span className='item-header-price'>Price</span>
                    <span className='item-header-amount'>Amount</span>
                </div>
                {details.items && details.items.map((item, index)=>(
                    <div key={index} className={`item-row ${index%2===0 ? 'bg-white' : 'bg-item-dark'}`}>
                        <span className='item-header-s-no'>{index+1}</span>
                        <span className='item-header-name'>{item.name}</span>
                        <span className='item-header-hsn'>{item.hsn}</span>
                        <span className='item-header-qty'>{item.quantity}</span>
                        <span className='item-header-price'>{item.price}</span>
                        <span className='item-header-amount'>{item.quantity * item.price}</span>
                    </div>
                ))}
                {/* Total amount  and T&C*/}
                <div className="total-tnc">
                    {/* Words and T&C */}
                    <div className='words-tnc'>
                        <p className='num-to-words'>{numWords(Math.trunc(details.totalAmount))} Only</p>
                        <div className='amount-breakage'>
                            <p>Tax Rate <br /> <span>{details.taxPercentage}%</span></p>
                            <p>Taxable Amt. <br /> <span>{parseFloat(taxableAmount).toFixed(2)}</span></p>
                            {details.taxCategory === "IN" ? <p>IGST <br /> <span>Rs. {parseFloat((details.subTotalAmount - discountAmount) *details.taxPercentage/100).toFixed(2)}</span></p> : 
                              <>
                                <p>CGST <br /> <span>Rs. {parseFloat((details.subTotalAmount - discountAmount) *(details.taxPercentage/2)/100).toFixed(2)}</span></p>
                                <p>SGST <br /> <span>Rs. {parseFloat((details.subTotalAmount - discountAmount) *(details.taxPercentage/2)/100).toFixed(2)}</span></p>
                              </>
                            }
                            <p>Total Tax <br /> <span>Rs. {parseFloat((details.subTotalAmount - discountAmount) *details.taxPercentage/100).toFixed(2)}</span></p>
                        </div>
                        <div className='pe-5'>
                            <h6 className='text-dark'>Terms & Conditions:</h6>
                            <span>1. Goods once sold will not be taken back.</span>
                            <br />
                            <span>2. Our Responsibility ceases after the goods delivered and No Claim breakage etc. will be expected.</span>
                            <br />
                            <span>3. All disputes Subject to Delhi Jurisdiction Only</span>
                        </div>
                    </div>
                    {/* Total Counter */}
                    <div className="total-counter">
                        {/* Subtotal and Tax */}
                        <div className="p-3 bg-primary">
                          <p className='flex-between mb-1 text-white'><span>Sub-Total</span><span className='fw-semibold'>Rs. {parseFloat(details.subTotalAmount).toFixed(2)}</span></p>
                          <p className='flex-between mb-1 text-white'><span>Discount ({details.discount}%) </span><span className='fw-semibold'>Rs. {parseFloat(discountAmount).toFixed(2)}</span></p>
                          <p className='flex-between mb-1 text-white'><span>Taxable Amt</span><span className='fw-semibold'>Rs. {parseFloat(details.taxableAmount).toFixed(2)}</span></p>
                          {details.taxCategory === "IN" ? <p className='flex-between mb-1 text-white'><span>IGST ({details.taxPercentage}%)</span><span className='fw-semibold'>Rs. {parseFloat((details.subTotalAmount - discountAmount) *details.taxPercentage/100).toFixed(2)}</span></p> : <>
                                <p className='flex-between mb-1 text-white'><span>CGST ({details.taxPercentage/2}%)</span><span className='fw-semibold'>Rs. {parseFloat((details.subTotalAmount - discountAmount) *(details.taxPercentage/2)/100).toFixed(2)}</span></p>
                                <p className='flex-between mb-0 text-white'><span>SGST ({details.taxPercentage/2}%)</span><span className='fw-semibold'>Rs. {parseFloat((details.subTotalAmount - discountAmount) *(details.taxPercentage/2)/100).toFixed(2)}</span></p>
                          </>}
                        </div>
                        <div className="bg-black p-3">
                          <h4 className='fw-bold flex-between mb-0 text-white'><span>Grand Total</span><span>Rs. {Math.round(details.totalAmount)}</span></h4>
                        </div>
                    </div>
                </div>
                {/* Qr Code & Signature */}
                <div className="qrcode-signature">
                    <div className='d-flex'>
                        <div className='d-flex flex-column me-2'>
                            <div className="qr-div" style={{backgroundImage:"url('/assets/img/qrcode.png')"}}></div>
                            <button className='btn btn-dark rounded'>Scan QR For Payment</button>
                        </div>
                        <div className='bank-details'>
                            <h5 className="fw-bold mb-0 text-dark">Bank Details</h5>
                            <p><strong>Name:</strong> Omtech Auto</p>
                            <p><strong>A/C No:</strong> 33150200000217</p>
                            <p><strong>Bank Name:</strong> Bank Of Baroda</p>
                            <p><strong>IFSC:</strong> BARB0BURARI</p>
                            <p><strong>Branch:</strong> BURARI BRANCH, DELHI-110084</p>
                            <p><strong>GSTIN No.:</strong> 07AEGPA1899B1ZS</p>
                            <img src="/assets/img/upi.png" alt="" />
                        </div>
                    </div>
                    <div className='signature-div'>
                        <h5>For OMTECH AUTO</h5>
                        <p>Authorised Signatory</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default PrintHandler