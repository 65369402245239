import React, { useEffect, useState } from 'react'
import Loading from '../../components/utility/Loading'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getChallanDetails } from '../../redux/actions/challanActions'
import PrintChallanComponent from '../../components/Challans/PrintChallanComponent'
import PrintChallanPageHandler from '../../components/Challans/PrintChallanPageHandler'

const PrintChallan = () => {

    const { id } = useParams()
    const dispatch = useDispatch()
    const {challans} = useSelector(state=>state.challans)

    const [challan, setChallan] = useState()
    const [loading, setLoading] = useState(true)
    const [printCount, setPrintCount] = useState(0)
    const [totalQuantity, setTotalQuantity] = useState(0)
    let printChallan = (new URLSearchParams(window.location.search)).get("print")

    const challansPerPage = 16

    useEffect(() => {
        const isChallanExist = challans.find((i) => i._id === id)
        if (isChallanExist) {
            setChallan(isChallanExist)
            setLoading(false)
        } else {
            dispatch(getChallanDetails(id)).then((res) => {
                if (res.success) {
                    setChallan(res.challan)
                    setLoading(false)
                }
            })
        }
    }, [])

    useEffect(() => {
        if (challan && challan.items && printCount<1 && printChallan) {
            setTimeout(() => {
                setPrintCount(printCount + 1)
                handlePrint()
            }, 1000);
        }
        if (challan && challan.items) {
            let quantity = 0
            for (let i = 0; i < challan.items.length; i++) {
              const item = challan.items[i];
              quantity = quantity + item.quantity
            }
            setTotalQuantity(quantity)
        }
    }, [challan, printCount])
    

    const handlePrint = () => {
        window.print();
      };
    
  return loading ? (<Loading/>) : (
    <>
        <nav className='navbar navbar-light position-lg-sticky top-lg-0 overlap-10 flex-none bg-primary border-bottom px-0 py-3 d-print-none'>
            <div className="container-fluid d-flex align-items-center justify-content-between">
                <Link to='/' className='text-white h6 fw-bold'>Omtech Auto</Link>
                <div><span onClick={handlePrint} className="p-2 px-4 bg-new-success rounded text-white text-l fw-semibold cursor-pointer"><i className="bi bi-printer"></i> Print Challan</span></div>

            </div>
        </nav>
        <div className="d-print-none">
            <PrintChallanComponent challan={challan} display={true} showTotal={true} totalQty={totalQuantity} />
        </div>
        <PrintChallanPageHandler challan={challan} challansPerPage={challansPerPage} />
    </>
  )
}

export default PrintChallan