import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Loader from './Loader'
import { useDispatch, useSelector } from 'react-redux'
import { getPerformaInvoices } from '../redux/actions/performaInvoiceAction'

const PerformaInvoicesLoader = () => {

  const dispatch = useDispatch()
  const {performaInvoices, loading} = useSelector(state=>state.performaInvoices)

  useEffect(() => {
    if (performaInvoices.length===0) {
        dispatch(getPerformaInvoices())
      }
}, [])

  return loading ? <Loader /> : <Outlet />

}

export default PerformaInvoicesLoader