import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import { filterBills } from '../../utility/bills';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const BillToSupplyTo = ({billTo, setBillTo, sameAsBillTo, setSameAsBillTo, placeOfSupply, setPlaceOfSupply, modeOfTransprot, setModeOfTransport, selectedPriceList, setSelectedPriceList, billOrPI}) => {

  const {bills} = useSelector(state=>state.bills)
  const {performaInvoices} = useSelector(state=>state.performaInvoices)
  const {priceLists} = useSelector(state=>state.priceLists)

  const billsAndPiMerger = () => {
    const combinedData = bills.concat(performaInvoices);
    return combinedData
  }
  
  const handleAutoCompleteForBills = (e, details, type) => {
    if (type==="onChange") {
      // onChange means that the value is being selected by clicking
      setBillTo({name: details.billTo.name, address: details.billTo.address, gst: details.billTo.gst, state: details.billTo.state, stateCode: details.billTo.stateCode})
      setPlaceOfSupply({name: details.placeOfSupply.name, address: details.placeOfSupply.address, gst: details.placeOfSupply.gst, state: details.placeOfSupply.state, stateCode: details.placeOfSupply.stateCode})
      setSameAsBillTo(checkforSameAsBillTo(details))
      setSelectedPriceList(setPriceListHandler(details))
    } else {
      setBillTo({...billTo, name: details})
    }
  }

  const setPriceListHandler = (details) => {
    console.log(details)
    let priceListToBeSelected
    if (details.priceList) {
      const isItemExists = priceLists.find((i) => i.name === details.priceList)
      return isItemExists
    } else {
      return priceListToBeSelected
    }
  }

  const checkforSameAsBillTo = (details) => {
    let same = false
    if (details.billTo.name === details.placeOfSupply.name && details.billTo.address === details.placeOfSupply.address && details.billTo.gst === details.placeOfSupply.gst && details.billTo.state === details.placeOfSupply.state && details.billTo.stateCode === details.placeOfSupply.stateCode) {
      same = true
    } else {
      same = false
    }
    return same
  }

  return (
    <section className='py-4'>
      <h5>Bill To</h5>
      <div className="row">
        <div className="col-12 col-md-6">
            <Autocomplete
              id="custom-input-demo"
              autoHighlight
              freeSolo
              value={{billTo}}
              options={filterBills(billsAndPiMerger())}
              getOptionLabel={(option) => option.billTo.name}
              renderInput={(params) => (
                <div className='input-billing' ref={params.InputProps.ref}>
                  <input placeholder='Name' type="text" style={{width:"100%", background:"none"}} {...params.inputProps} />
                </div>
              )}
              // This is the display value
              onInputChange={(event, newInputValue) => {
                handleAutoCompleteForBills(event, newInputValue, "onInputChange");
              }}
              // This is the actual value
              onChange={(event, newValue) => {
                handleAutoCompleteForBills(event, newValue, "onChange");
              }}
            />
          {/* {type==='edit' && <input className='input-billing' autoComplete="off" type="text" placeholder='Name' name='gst' value={billTo.name} onChange={(e)=>setBillTo({...billTo, name: e.target.value})}/>} */}
          <textarea className='input-billing' autoComplete="off" type="text" placeholder='Address' name='address' style={{minHeight:"85px"}} value={billTo.address} onChange={(e)=>setBillTo({...billTo, address: e.target.value})}/>
        </div>
        <div className="col-12 col-md-6">
          <input className='input-billing' autoComplete="off" type="text" placeholder='GST' name='gst' value={billTo.gst} onChange={(e)=>setBillTo({...billTo, gst: e.target.value})}/>
          <input className='input-billing' autoComplete="off" type="text" placeholder='State' name='state' value={billTo.state} onChange={(e)=>setBillTo({...billTo, state: e.target.value})}/>
          <input className='input-billing' autoComplete="off" type="number" placeholder='State Code' name='stateCode' value={billTo.stateCode} onChange={(e)=>setBillTo({...billTo, stateCode: e.target.value})}/>
        </div>
      </div>
      <h5 className='mt-4'>Place Of Supply <FormControlLabel control={<Checkbox checked={sameAsBillTo} onChange={(e)=>{setSameAsBillTo(!sameAsBillTo)}} />} label="(Same As Bill To)" /></h5>
      {!sameAsBillTo && <div className="row">
        <div className="col-12 col-md-6">
          <input className='input-billing' autoComplete="off" type="text" placeholder='Name' name='name' value={placeOfSupply.name} onChange={(e)=>setPlaceOfSupply({...placeOfSupply, name: e.target.value})}/>
          <textarea className='input-billing' autoComplete="off" type="text" placeholder='Address' name='address' style={{minHeight:"85px"}} value={placeOfSupply.address} onChange={(e)=>setPlaceOfSupply({...placeOfSupply, address: e.target.value})}/>
        </div>
        <div className="col-12 col-md-6">
          <input className='input-billing' autoComplete="off" type="text" placeholder='GST' name='gst' value={placeOfSupply.gst} onChange={(e)=>setPlaceOfSupply({...placeOfSupply, gst: e.target.value})}/>
          <input className='input-billing' autoComplete="off" type="text" placeholder='State' name='state' value={placeOfSupply.state} onChange={(e)=>setPlaceOfSupply({...placeOfSupply, state: e.target.value})}/>
          <input className='input-billing' autoComplete="off" type="number" placeholder='State Code' name='stateCode' value={placeOfSupply.stateCode} onChange={(e)=>setPlaceOfSupply({...placeOfSupply, stateCode: e.target.value})}/>
        </div>
      </div>}
      <h5 className='mt-4'>Mode Of Transprot</h5>
      <div className="row">
        <div className="col-12 col-md-6">
          <input className='input-billing' autoComplete="off" type="text" placeholder='Mode Of Transport' name='mode' value={modeOfTransprot.mode} onChange={(e)=>setModeOfTransport({...modeOfTransprot, mode: e.target.value})}/>
        </div>
        <div className="col-12 col-md-6">
          <input className='input-billing' autoComplete="off" type="text" placeholder='Vehicle No.' name='vehicleNo' value={modeOfTransprot.vehicleNo} onChange={(e)=>setModeOfTransport({...modeOfTransprot, vehicleNo: e.target.value})}/>
        </div>
      </div>
      <h5 className='mt-5'>Price List</h5>
      <div className="row">
        <div className="col-12 col-md-6">
          <FormControl className="w-100 mt-4" size="small">
            <InputLabel id="demo-simple-select-label">Price List</InputLabel>
              <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedPriceList ? selectedPriceList : ''}
                  label="Price List"
                  onChange={(e) => setSelectedPriceList(e.target.value)}
                >
                  {priceLists.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
          </FormControl>
        </div>
      </div>
    </section>
  )
}

BillToSupplyTo.defaultProps = {
  billOrPI: 'bill'
}

export default BillToSupplyTo