import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createPerformaInvoice, getPerformaInvoiceDetails } from '../../redux/actions/performaInvoiceAction'
import HeadingAndDate from '../../components/BillingSystem/HeadingAndDate'
import BillToSupplyTo from '../../components/BillingSystem/BillToSupplyTo'
import ItemsHandler from '../../components/BillingSystem/ItemsHandler'
import SaveAndTotal from '../../components/BillingSystem/SaveAndTotal'
import { useNavigate } from 'react-router-dom'
import { getBillDetails } from '../../redux/actions/billAction'

const NewPerformaInvoice = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {bills} = useSelector(state=>state.bills)
  const {priceLists} = useSelector(state=>state.priceLists)
  const {performaInvoices} = useSelector(state=>state.performaInvoices)

  const [buttonLoading, setButtonLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false);

  const [date, setDate] = useState(moment())
  const [items, setItems] = useState([])
  const [newItemDetails, setNewItemDetails] = useState({ name: '', quantity: '', price: '', totalAmount: 0 })
  const [billTo, setBillTo] = useState({name:"", gst: "", address: "", state: "", stateCode: ""})
  const [placeOfSupply, setPlaceOfSupply] = useState({name:"", gst: "", address: "", state: "", stateCode: ""})
  const [modeOfTransport, setModeOfTransport] = useState({mode: "", vehicleNo: ""})
  const [subTotalAmount, setSubTotalAmount] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [totalAmount, setTotalAmount] = useState()
  const [taxCategory, setTaxCategory] = useState("IN");
  const [taxPercentage, setTaxPercentage] = useState(18)
  const [sameAsBillTo, setSameAsBillTo] = useState(true);
  const [selectedPriceList, setSelectedPriceList] = useState()

  let duplicate = (new URLSearchParams(window.location.search)).get("duplicate")
  let duplicateId = (new URLSearchParams(window.location.search)).get("duplicateId")
  let convert = (new URLSearchParams(window.location.search)).get("convert")
  let convertId = (new URLSearchParams(window.location.search)).get("convertId")

  useEffect(() => {
    if (duplicate && duplicateId) {
      duplicateHandler()
    } else if (convert && convertId) {
      convertHandler()
    }
  }, [])

  const duplicateHandler = () => {
    setLoading(true)
    const isPerformaInvoiceExist = performaInvoices.find((i) => i._id === duplicateId)
    if (isPerformaInvoiceExist) {
      setStatesForDuplicationAndConversion(isPerformaInvoiceExist)
    } else {
      dispatch(getPerformaInvoiceDetails(duplicateId)).then((res) => {
        if (res.success) {
          setStatesForDuplicationAndConversion(res.performaInvoice)
        }
      })
    }
  }

  const convertHandler  = () => {
    setLoading(true)
    const isBillExist = bills.find((i) => i._id === convertId)
    if (isBillExist) {
      setStatesForDuplicationAndConversion(isBillExist)
    } else {
      dispatch(getBillDetails(convertId)).then((res) => {
        if (res.success) {
          setStatesForDuplicationAndConversion(res.bill)
        }
      })
    }
  }

  
  const setStatesForDuplicationAndConversion = (details) => {
    // setDate(details.createdAt)
    setItems(details.items)
    setBillTo(details.billTo)
    setPlaceOfSupply(details.placeOfSupply)
    setModeOfTransport(details.modeOfTransport)
    setSubTotalAmount(details.subTotalAmount)
    setDiscount(details.discount)
    setTotalAmount(details.totalAmount)
    setTaxCategory(details.taxCategory)
    setTaxPercentage(details.taxPercentage)
    setSameAsBillTo(checkforSameAsBillTo(details))
    setSelectedPriceList(priceLists.find((i) => i.name === details.priceList))
    setLoading(false)
  }

  const checkforSameAsBillTo = (details) => {
    let same = false
    if (details.billTo.name === details.placeOfSupply.name && details.billTo.address === details.placeOfSupply.address && details.billTo.gst === details.placeOfSupply.gst && details.billTo.state === details.placeOfSupply.state && details.billTo.stateCode === details.placeOfSupply.stateCode) {
      same = true
    } else {
      same = false
    }
    return same
  }


  useEffect(() => {
    // Updating Sub Total Amt
    if (items.length>0) {
        let amount = 0
        for (let i = 0; i < items.length; i++) {
          const itemTotalAmount = items[i].quantity * items[i].price;
          amount = amount + itemTotalAmount
        }
        setSubTotalAmount(amount)
    }
    // Updating Tax Percentage
    if (items.length>0) {
      const isTax28 = items[0].hsn && items[0].hsn.toString() === '8708'
      const isTax18 = items[0].hsn && items[0].hsn.toString() === '7320'
      setTaxPercentage(isTax28 ? 28 : isTax18 ? 18 : taxPercentage)
    }
  }, [items])
  
  const handleSubmit = (action) => {
    if (!billTo.name) {
      setError("Plase fill PerformaInvoice To Details atleast")
      return
    }
    setButtonLoading(true)
    const data = {
      billTo: billTo,
      placeOfSupply: sameAsBillTo ? billTo : placeOfSupply,
      modeOfTransport: modeOfTransport,
      items: items,
      subTotalAmount: subTotalAmount,
      discount: discount,
      taxPercentage: taxPercentage,
      taxCategory: taxCategory,
      taxableAmount: subTotalAmount - (subTotalAmount * discount/100),
      totalTax: (subTotalAmount - (subTotalAmount * discount/100)) *taxPercentage/100,
      totalAmount: Math.round(totalAmount),
      priceList: selectedPriceList ? selectedPriceList.name : 'Pai Spice' ,
      createdAt: JSON.stringify(date).substring(1, JSON.stringify(date).length -1)
    }
    dispatch(createPerformaInvoice(data))
    .then(res => {
      if (res.success) {
        action === "saveAndPrint" ? navigate(`/performaInvoice/print/${res.performaInvoice._id}?print=true`) : navigate('/performaInvoices')
      } else {
        setError(res.message)
      }
      setButtonLoading(false)
    })
  }


  return (
    <section className='py-5 billing-system'>
      <HeadingAndDate date={date} setDate={setDate} type={'PI'} />
      <BillToSupplyTo billTo={billTo} setBillTo={setBillTo} placeOfSupply={placeOfSupply} setPlaceOfSupply={setPlaceOfSupply} sameAsBillTo={sameAsBillTo} setSameAsBillTo={setSameAsBillTo} modeOfTransprot={modeOfTransport} setModeOfTransport={setModeOfTransport} selectedPriceList={selectedPriceList} setSelectedPriceList={setSelectedPriceList} billOrPI={'PI'} />
      <ItemsHandler items={items} setItems={setItems} newItemDetails={newItemDetails} setNewItemDetails={setNewItemDetails} selectedPriceList={selectedPriceList} />
      <SaveAndTotal taxCategory={taxCategory} setTaxCategory={setTaxCategory} subTotalAmount={subTotalAmount} totalAmount={totalAmount} setTotalAmount={setTotalAmount} buttonLoading={buttonLoading} handleSubmit={handleSubmit} discount={discount} setDiscount={setDiscount} taxPercentage={taxPercentage} setTaxPercentage={setTaxPercentage} error={error} stateCode={billTo.stateCode} newItemDetails={newItemDetails} />
    </section>
  )
}

export default NewPerformaInvoice