import React, { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { CheckCircleRounded } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import { priceListItemsBundler } from '../../utility/products';

const ItemsHandler = ({items, setItems, newItemDetails, setNewItemDetails, selectedPriceList}) => {

  const [products, setProducts] = useState([])

  useEffect(() => {
    if (selectedPriceList) {
      setProducts(priceListItemsBundler(selectedPriceList))
    }
  }, [selectedPriceList])

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index] = { ...updatedItems[index], [field]: value,};
    setItems(updatedItems);
  };

  const handleNewItemChange = (field, value) => {
    setNewItemDetails({ ...newItemDetails, [field]: value,});
  };

  const handleDeleteItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const handleAddNewItem = () => {
    if (newItemDetails.name) {
      const gasSpringAddedName = {...newItemDetails, name: `Gas Spring ${newItemDetails.name}`}
      console.log(newItemDetails, gasSpringAddedName)
      setItems([...items, gasSpringAddedName]);
      setNewItemDetails({ name: '', hsn: '', quantity: '', price: '',});
    }
  };

  const handleAutoComplete = (e, detials, type) => {
    if (type==="onChange") {
      // onChange means that the value is being selected by clicking
      setNewItemDetails({...newItemDetails, name: detials.name, price: detials.price, hsn: 8708})
    } else {
      setNewItemDetails({...newItemDetails, name: detials})
    }
  }

  
  return (
    <section className='billing-items'>
        <h5 className='mt-5 mb-3'>Items</h5>
        <div className="item-header">
            <span className='item-header-name'>Name</span>
            <span className='item-header-hsn'>HSN</span>
            <span className='item-header-qty'>Qty</span>
            <span className='item-header-price'>Price</span>
            <span className='item-header-amount'>Amount</span>
        </div>
        {items && items.map((item, index)=>(
            <div key={index} className={`item-row ${index%2===0 ? 'bg-item-light' : 'bg-item-dark'}`}>
                <input placeholder='Name' type='text' onChange={(e) => handleItemChange(index, 'name', e.target.value)} value={item.name} className='item-header-name'/>
                <input placeholder='HSN' type='number' onChange={(e) => handleItemChange(index, 'hsn', e.target.value)} value={item.hsn} className='item-header-hsn'/>
                <input placeholder='QTY' type='number' onChange={(e) => handleItemChange(index, 'quantity', e.target.value)} value={item.quantity} className='item-header-qty'/>
                <input placeholder='Price' type='number' onChange={(e) => handleItemChange(index, 'price', e.target.value)} value={item.price} className='item-header-price'/>
                <div className='item-header-amount'>
                  <span>{item.quantity * item.price}</span>
                  <IconButton color="error" size='small' onClick={() => handleDeleteItem(index)}><DeleteIcon /></IconButton>
                </div>
            </div>
        ))}
        <div className={`item-row bg-item-new`}>
          <Autocomplete
              className='item-header-name'
              id="custom-input-demo"
              autoHighlight
              freeSolo
              options={products}
              getOptionLabel={(option) => option.name.toUpperCase()}
              renderInput={(params) => (
                <div className='w-100' ref={params.InputProps.ref}>
                  <input placeholder='Name' type="text" style={{width:"100%", background:"none"}} {...params.inputProps} />
                </div>
              )}
              inputValue={newItemDetails.name}
              // This is the display value
              onInputChange={(event, newInputValue) => {
                  handleAutoComplete(event, newInputValue, "onInputChange");
              }}
              // This is the actual value
              onChange={(event, newValue) => {
                  handleAutoComplete(event, newValue, "onChange");
              }}
          />
          {/* <input placeholder='Name' type='text' onChange={(e) => handleNewItemChange('name', e.target.value)} value={newItemDetails.name} className='item-header-name'/> */}
          <input placeholder='HSN' type='number' onChange={(e) => handleNewItemChange('hsn', e.target.value)} value={newItemDetails.hsn} className='item-header-hsn'/>
          <input placeholder='QTY' type='number' onChange={(e) => handleNewItemChange('quantity', e.target.value)} value={newItemDetails.quantity} className='item-header-qty'/>
          <input placeholder='Price' type='number' onChange={(e) => handleNewItemChange('price', e.target.value)} value={newItemDetails.price} className='item-header-price'/>
          <div className='item-header-amount'>
            <span>{newItemDetails.quantity * newItemDetails.price}</span>
            <IconButton color="success" size='small' onClick={handleAddNewItem}><CheckCircleRounded /></IconButton>
          </div>
      </div>
    </section>
  )
}

export default ItemsHandler