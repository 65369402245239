import React, { useEffect, useState } from 'react'
import Loader from '../../utility/Loader'
import moment from 'moment'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers'
import { useDispatch, useSelector } from 'react-redux';
import { getCustomers, getFilterChallans } from '../../redux/actions/analyticsAction';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';


const Accounts = () => {

    const dispatch = useDispatch();
    const { filteredChallans, customers, loading } = useSelector((state) => state.analytics);

    let fromDate = (new URLSearchParams(window.location.search)).get("from")
    let toDate = (new URLSearchParams(window.location.search)).get("to")
    let searchName = (new URLSearchParams(window.location.search)).get("name")
    let exactNameCheckParams = (new URLSearchParams(window.location.search)).get("exactNameCheck")

    const [from, setFrom] = useState(moment(fromDate).startOf('day')); // Default 'from' 7 days ago
    const [to, setTo] = useState(moment(toDate).startOf('day'));
    const [name, setName] = useState(searchName ? searchName : "")
    const [exactNameCheck, setExactNameCheck] = useState(exactNameCheckParams ? exactNameCheckParams : true)
    const [filters, setFilters] = useState({ from, to, name, exactNameCheck });

    const [ascendingDate, setAscendingDate] = useState(false)
    const [sortedChallans, setSortedChallans] = useState(filteredChallans)
    const [totalAmount, setTotalAmount] = useState(0)

    useEffect(() => {
        dispatch(getCustomers())
        if (from && to && name) {
            dispatch(getFilterChallans({from, to, name, exactNameCheck}));
        }
    }, [])
    

    const handleNameSearch = (customerName) => {
        setName(customerName)
        setFilters({from, to, name: customerName, exactNameCheck})
    }

    const submit = () => {
        if (from && to && name) {
            dispatch(getFilterChallans({from, to, name, exactNameCheck}));
            setFilters({from, to, name, exactNameCheck})
        }
      }

    const inrFormatter = (value) =>
    new Intl.NumberFormat('en-IN').format(value);

    const handleAscending = () => {
        setAscendingDate(!ascendingDate)
        setSortedChallans(sortedChallans.reverse())
    }

    useEffect(() => {
            setSortedChallans(filteredChallans)
    }, [filteredChallans])


    useEffect(() => {
        if (filteredChallans.length > 0) {
            let tempTotal = 0
            for (let i = 0; i < filteredChallans.length; i++) {
                const item = filteredChallans[i];
                tempTotal += item.totalAmount
            }
            setTotalAmount(tempTotal)
        }
    }, [filteredChallans])

    const handlePrint = () => {
        window.print();
      };


      const handleAutoCompleteForBills = (e, details, type) => {
        if (type==="onChange") {
          // onChange means that the value is being selected by clicking
          setName(details._id)
        } else {
          setName(details)
        }
      }


  return loading ? (<Loader/>) : (
      <section className='container-fluid py-5'>
          <div className="d-print-none">
          <div className="pb-5"><h2 className="text-primary text-center">Accounts</h2></div>
          {/* Filter */}
          <div className="d-flex gap-5 flex-wrap">
              <div className="position-relative d-flex mr-3">
                  <span>From - </span>
                  <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-gb'}>
                      <MobileDatePicker
                          value={from}
                          onChange={(newValue) => {
                              setFrom(newValue);
                              setFilters({ ...filters, from: newValue });
                          }}
                          renderInput={({ inputRef, inputProps, InputProps }) => (
                              <>
                                  <p className="text-primary text-underline fw-bold cursor-pointer" ref={inputRef} {...inputProps}>
                                      {moment(from).startOf('day').format('D MMM YYYY')}
                                  </p>
                                  {InputProps?.endAdornment}
                              </>
                          )}
                      />
                  </LocalizationProvider>
              </div>
              <div className="position-relative d-flex">
                  <span>To - </span>
                  <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-gb'}>
                      <MobileDatePicker
                          value={to}
                          onChange={(newValue) => {
                              setTo(newValue);
                              setFilters({ ...filters, to: newValue });
                          }}
                          renderInput={({ inputRef, inputProps, InputProps }) => (
                              <>
                                  <p className="text-primary text-underline fw-bold cursor-pointer" ref={inputRef} {...inputProps}>
                                      {moment(to).startOf('day').format('D MMM YYYY')}
                                  </p>
                                  {InputProps?.endAdornment}
                              </>
                          )}
                      />
                  </LocalizationProvider>
              </div>
          </div>
          {/* Search & Submit */}
          <div className='flex-between gap-5'>
              <div class="w-100 my-3">
                    <Autocomplete
                    id="custom-input-demo"
                    autoHighlight
                    freeSolo
                    value={{_id: name}}
                    options={(customers)}
                    getOptionLabel={(option) => option._id}
                    renderInput={(params) => (
                        <div className='d-flex' ref={params.InputProps.ref}>
                            <input {...params.inputProps} autoComplete='off' type="text" class="form-control" placeholder="Name" aria-label="Recipient's username" aria-describedby="button-addon2" />
                            <button class="btn btn-primary" type="button" id="button-addon2" onClick={submit}>Search</button>
                        </div>
                    )}
                    // This is the display value
                    onInputChange={(event, newInputValue) => {
                        handleAutoCompleteForBills(event, newInputValue, "onInputChange");
                    }}
                    // This is the actual value
                    onChange={(event, newValue) => {
                        handleAutoCompleteForBills(event, newValue, "onChange");
                    }}
                    />
              </div>
          </div>
          <button className='btn btn-sm btn-success my-4' onClick={handlePrint}>Print</button>
          </div>
          {/* Challans */}
          <div>
              <h4>Total Challans - {filteredChallans.length}</h4>
              {/* <FormControlLabel control={<Radio />} className="tax-category-label" checked={ascendingDate === true} value={ascendingDate} onChange={handleAscending} label={`Oldeset First`} labelPlacement="start" /> */}
              <h5 className='mt-4 d-print-none'>Oldeset First <FormControlLabel control={<Checkbox checked={ascendingDate} onChange={handleAscending} />} label="" /></h5>
              <h5 className='mt-4 d-print-none'>Exact Name Match <FormControlLabel control={<Checkbox checked={exactNameCheck} onChange={(e)=>setExactNameCheck(!exactNameCheck)} />} label="" /></h5>

              <div className="mt-5">
                  <div className="row bg-dark text-white">
                      <div className="col-1 col-md-1 col-lg-1">#</div>
                      <div className="col-6 col-md-5 col-lg-7">Name</div>
                      <div className="col-3 col-md-3 col-lg-2">Date</div>
                      <div className="col-2 col-md-3 col-lg-2 text-end">Total</div>
                  </div>
                  {sortedChallans?.map((item, index) => (
                      <div className={`row border ${index % 2 === 0 ? 'bg-white' : 'bg-gray-300'}`}>
                          <div className="col-1 col-md-1 col-lg-1">{index + 1} ({item.challanNumber})</div>
                          <div className="col-6 col-md-5 col-lg-7">{item.name} ({item.note})</div>
                          <div className="col-3 col-md-3 col-lg-2">{moment(item.createdAt).format('D MMM YY')}</div>
                          <div className="col-2 col-md-3 col-lg-2 text-end">₹ {inrFormatter(item.totalAmount)}</div>
                      </div>
                  ))}
                    <div className="row">
                      <div className="col-1 col-md-1 col-lg-1"></div>
                      <div className="col-1 col-md-1 col-lg-1"></div>
                      <div className="col-1 col-md-1 col-lg-1"></div>
                      <div className="col-9 col-md-9 col-lg-9 text-end">Total Amount - {inrFormatter(totalAmount)}</div>
                    </div>
              </div>
          </div>
      </section>
  )
}

export default Accounts