import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOAD_USER_FAIL,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOGOUT_USER_FAIL,
    LOGOUT_USER_REQUEST,
    LOGOUT_USER_SUCCESS,
    CLEAR_ERRORS
} from "../constants/userConstant"

const initialState = {
    user: {role:"Admin"},
    isAuthenticated: false,
}

export const userReducer = (state = initialState, action) => {

    switch (action.type) {
        case LOGIN_REQUEST:
            
            return {
                ...state,
                loading: true,
            }
        
        case LOGIN_SUCCESS:
        
            return {
                ...state,
                loading: false,
                error: null,
                isAuthenticated: true,
                user: action.payload,
            }

        case LOGIN_FAIL:
    
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                user: null,
                error: action.payload
            }

        case LOAD_USER_REQUEST:
        
        return {
            ...state,
            loading: true,
        }
        
        case LOAD_USER_SUCCESS:
        
            return {
                ...state,
                loading: false,
                error: null,
                isAuthenticated: true,
                user: action.payload,
            }

        case LOAD_USER_FAIL:
    
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                user: null,
                error: action.payload
            }
            case LOGOUT_USER_REQUEST:
        
                return {
                    ...state,
                    loading: true,
                }
                
            case LOGOUT_USER_SUCCESS:
            
                return {
                    ...state,
                    loading: false,
                    error: null,
                    isAuthenticated: false,
                    user: {},
                }
    
            case LOGOUT_USER_FAIL:
        
                return {
                    ...state,
                    loading: false,
                    user: null,
                    error: action.payload
                }

        case CLEAR_ERRORS:
    
            return {
                ...state,
                error: null
            }
    
        default:
            return state
    }

}
