import axios from "axios";
import { API } from "../../Backend";
import {
    ALL_PI_FAIL,
    ALL_PI_REQUEST,
    ALL_PI_SUCCESS,
    CREATE_PI_FAIL,
    CREATE_PI_REQUEST,
    CREATE_PI_SUCCESS,
    PI_DETAILS_REQUEST,
    PI_DETAILS_SUCCESS,
    PI_DETAILS_FAIL,
    DELETE_PI_FAIL,
    DELETE_PI_REQUEST,
    DELETE_PI_SUCCESS,
    UPDATE_PI_FAIL,
    UPDATE_PI_REQUEST,
    UPDATE_PI_SUCCESS,
    CLEAR_ERRORS
} from "../constants/performaInvoiceConstant"

export const getPerformaInvoices = () => async(dispatch) => {
    try {
        dispatch({ type: ALL_PI_REQUEST })

        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }
        
        const { data } = await axios.get(`${API}/performaInvoice/get`, config)
        dispatch({type: ALL_PI_SUCCESS, payload: data})

    } catch (error) {
        dispatch({
            type: ALL_PI_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getPerformaInvoiceDetails = (id) => async(dispatch) => {
    try {
        dispatch({ type: PI_DETAILS_REQUEST })
        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }
        
        const {data} = await axios.get(`${API}/performaInvoice/getSingle/${id}`, config)
        dispatch({type: PI_DETAILS_SUCCESS, payload: data.performaInvoice})
        return data
    } catch (error) {
        dispatch({
            type: PI_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const createPerformaInvoice = (details) => async(dispatch) => {
    try {
        dispatch({type: CREATE_PI_REQUEST})
        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }
        const {data} = await axios.post(`${API}/performaInvoice/post`, details, config)
        dispatch({type: CREATE_PI_SUCCESS, payload: data})
        return data

    } catch (error) {
        dispatch({
            type: CREATE_PI_FAIL,
            payload: error.response.data.message
        })
        return error.response.data
    }
}


export const deletePerformaInvoice = (id) => async(dispatch) => {
    try {
        dispatch({type: DELETE_PI_REQUEST})
        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }
        const { data } = await axios.delete(`${API}/performaInvoice/delete/${id}`, config)
        dispatch({type: DELETE_PI_SUCCESS, payload: id})
        return data

    } catch (error) {
        dispatch({
            type: DELETE_PI_FAIL,
            payload: error.response.data.message
        })
        return error.response.data
    }
}

export const updatePerformaInvoice = (id, details) => async(dispatch) => {
    try {
        dispatch({type: UPDATE_PI_REQUEST})
        const config = {
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            withCredentials: true
        }
        const {data} = await axios.put(`${API}/performaInvoice/put/${id}`, details, config)
        dispatch({type: UPDATE_PI_SUCCESS, payload: data})
        return data

    } catch (error) {
        dispatch({
            type: UPDATE_PI_FAIL,
            payload: error.response.data.message
        })
        return error.response.data
    }
}


// Used for cleaning errors
export const clearErrors = () => async(dispatch) => {
    dispatch({type: CLEAR_ERRORS})
}