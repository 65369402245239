export  const filterBills = (arr) => {
    // Create a Set to store unique names
    const uniqueNames = new Set();
  
    // Use filter to keep only objects with unique names
    const filteredArray = arr.filter((obj) => {
      // Check if the name is not in the Set (i.e., it's unique)
      if (!uniqueNames.has(obj.billTo.name.trim())) {
        // Add the name to the Set for future reference
        uniqueNames.add(obj.billTo.name.trim());
        return true; // Include the object in the filtered array
      }
      return false; // Exclude the object from the filtered array
    });
    return filteredArray;
    }