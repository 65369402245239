import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getPerformaInvoiceDetails } from '../../redux/actions/performaInvoiceAction'
import Loader from '../../utility/Loader'
import PrintHandler from '../../components/BillingSystem/PrintHandler'

const PrintPerformaInvoice = () => {

    const { id } = useParams()
    const dispatch = useDispatch()
    const {performaInvoices} = useSelector(state=>state.performaInvoices)

    const [performaInvoice, setPerformaInvoice] = useState()
    const [loading, setLoading] = useState(true)
    const [printCount, setPrintCount] = useState(0)
    let printPerformaInvoice = (new URLSearchParams(window.location.search)).get("print")

    useEffect(() => {
        const isPerformaInvoiceExist = performaInvoices.find((i) => i._id === id)
        if (isPerformaInvoiceExist) {
            setPerformaInvoice(isPerformaInvoiceExist)
            setLoading(false)
        } else {
            dispatch(getPerformaInvoiceDetails(id)).then((res) => {
                if (res.success) {
                    setPerformaInvoice(res.performaInvoice)
                    setLoading(false)
                }
            })
        }
    }, [])

    useEffect(() => {
        if (performaInvoice && performaInvoice.items && printCount<1 && printPerformaInvoice) {
            setTimeout(() => {
                setPrintCount(printCount + 1)
                handlePrint()
            }, 1000);
        }
    }, [performaInvoice, printCount])
    

    const handlePrint = () => {
        window.print();
      };
    

  return loading ? (<Loader/>) : 
  (
    <section className='billing-system'>
        <PrintHandler details={performaInvoice} type={"PI"} copyType={"Original Copy"} />
    </section>
  )
}

export default PrintPerformaInvoice