import moment from 'moment'
import React from 'react'

const FirstPage = () => {
  return (
    <div className="cover-page" style={{backgroundImage: "url(/assets/img/priceList/5.jpg)"}}>
        <p className='text-white'>20 April 2022 <br /> New Price List</p>
        <div className="price-list-details">
            <img src="/assets/img/paispice.png" alt="" />
            <h1>Omtech Auto</h1>
            <span className='mt-4'>Dicky /Trunk Shockers | For all car & buses <br /> as per OEA specification</span>
            <span className='mt-4'>Mobile:+91-9312254728, +91-9899595979</span>
            <span className='mt-4'>Website: www.Omtechauto.in</span>
        </div>
    </div>
  )
}

export default FirstPage