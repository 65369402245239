import React from 'react'

const NotAllowed = () => {
return (
<section className='bg-primary d-flex align-items-center justify-content-center text-center text-white'  style={{height: "100vh", width: "100vw", overflow: "hiddens"}}>
  <div className="container-fluid">
    <h1 className='ls-tight font-bolder display-6 text-white my-3'>We are sorry</h1>
    <p className='text-opacity-80'>You do not have the permission to access these routes.</p>
    <div className="w-56 h-56 bg-orange-500 rounded-circle position-fixed bottom-0 end-20 transform translate-y-1/3">
    </div>
  </div>
</section>
)
}

export default NotAllowed