import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { getPerformaInvoices } from '../../redux/actions/performaInvoiceAction'
import moment from 'moment/moment'
import Loader from '../../utility/Loader'
import DeleteHandler from '../../components/BillingSystem/DeleteHandler'

const PerformaInvoices = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {performaInvoices, loading} = useSelector(state=>state.performaInvoices)
    const [selectedPerformaInvoiceId, setSelectedPerformaInvoiceId] = useState()
    const [selectedPerformaInvoice, setSelectedPerformaInvoice] = useState()

    // Search States
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredPerformaInvoices, setFilteredPerformaInvoices] = useState(performaInvoices);


    useEffect(() => {
        if (performaInvoices.length===0) {
            dispatch(getPerformaInvoices())
          }
    }, [])

    useEffect(() => {
      if (performaInvoices.length>0) {
        setFilteredPerformaInvoices(performaInvoices)
      }
    }, [performaInvoices])
    
    
    const duplicateHandler = (id) => {
      navigate(`/performaInvoice/new?duplicate=true&duplicateId=${id}`)
    }

    const convertHandler = (id) => {
      navigate(`/bill/new?convert=true&convertId=${id}`)
    }

    const handleDelete = (id) => {
      setSelectedPerformaInvoiceId(id)
      document.getElementById("openDeleteHandlerModal").click()
    }

  // Function to update the filtered performaInvoices based on the search term
  const updateFilteredPerformaInvoices = (term) => {
    const filtered = performaInvoices.filter((product) =>
      product.billTo.name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredPerformaInvoices(filtered);
  };

  // Event handler for input change
  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    updateFilteredPerformaInvoices(term);
  };

  const navigateToViewPerformaInvoice = (id) => (
    navigate(`/performaInvoice/print/${id}`)
  )

  return loading ? (<Loader/>) : (
    <section className='billing-system'>
    <div>
      <div className="performaInvoice-search">
        <h2>All PI ({performaInvoices && performaInvoices.length})</h2>
        <div className="input-group my-3">
            <input type="text" className="form-control" placeholder="Search Performa Invoice" aria-label="Seach PerformaInvoice" aria-describedby="basic-addon" value={searchTerm} onChange={handleInputChange}/>
            <span className="input-group-text" id="basic-addon"><i className="bi bi-search"></i></span>
          </div>
      </div>
      {filteredPerformaInvoices.map((item, index) => (
      <div key={index} className="d-flex flex-column flex-lg-row align-items-start justify-content-start align-items-lg-center justify-content-lg-between flex-wrap py-3 gap-3">
        <div className="flex-grow-1 w-full w-lg-auto">
          <div onClick={()=>navigateToViewPerformaInvoice(item._id)} className={`cursor-pointer shadow d-flex flex-wrap align-items-start justify-content-between text-white p-3 rounded-2 fw-regular ${index%2===0 ? 'bd-purple-800' : 'bd-purple-400'}`}>
            <div className='d-flex'>
              <div className="me-2">{item.invoiceNumber}</div>
              <div className="">{item.billTo.name}</div>
            </div>
            <div>
              {moment(item.createdAt).format('D MMM YY')}
            </div>
          </div>
        </div>
        <div>
          <div className="dropdown">
              <button className="btn bd-gray-300 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Options</button>
              <ul className="dropdown-menu dropdown-menu-dark">
                <li><span className="dropdown-item cursor-pointer" onClick={()=>duplicateHandler(item._id)}>Duplicate PI</span></li>
                <li><span className="dropdown-item cursor-pointer" onClick={()=>convertHandler(item._id)}>Convert To Bill</span></li>
                <li><Link className="dropdown-item" to={`/performaInvoice/print/${item._id}?print=true`}>Print</Link></li>
                <li><Link className="dropdown-item" to={`/performaInvoice/${item._id}`}>Edit</Link></li>
                <li><span className="dropdown-item text-danger cursor-pointer" onClick={()=>handleDelete(item._id)}>Delete</span></li>
              </ul>
            </div>
          <DeleteHandler type={"performaInvoice"} id={selectedPerformaInvoiceId} />
          <button className='btn btn-primary d-none' data-bs-toggle="modal" data-bs-target="#deleteHandler" id='openDeleteHandlerModal'>Delete Handler</button>
        </div>
      </div>
      ))}

    </div>
</section>
  )
}

export default PerformaInvoices