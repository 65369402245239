import React from 'react'
import { Link } from 'react-router-dom'

const Analytics = () => {

  const links = [
    {name: "Products", link: "/analytics/topItemsSold", image:"/assets/img/3.jpg"},
    {name: "Total Sales", link: "/analytics/totalSales", image:"/assets/img/2.jpg"},
    {name: "Accounts", link: "/analytics/accounts", image:"/assets/img/5.jpg"},
    {name: "Customers", link: "/analytics/topCustomers", image:"/assets/img/1.jpg"},
    {name: "Taxes", link: "/analytics/taxSales", image:"/assets/img/4.jpg"},
  ]

  return (
    <section className='row'>
      {links.map((item, index)=>(
        <Link to={item.link} className='analytics-card col-lg-4 col-sm-12 col-md-6 mt-4'>
          <img src={item.image} className='w-100' alt="" />
          <p>{item.name}</p>
        </Link>
      ))}
    </section>
  )
}

export default Analytics