export const ALL_CHALLAN_REQUEST = "ALL_CHALLAN_REQUEST"
export const ALL_CHALLAN_SUCCESS = "ALL_CHALLAN_SUCCESS"
export const ALL_CHALLAN_FAIL = "ALL_CHALLAN_FAIL"
export const CHALLAN_DETAILS_REQUEST = "CHALLAN_DETAILS_REQUEST"
export const CHALLAN_DETAILS_SUCCESS = "CHALLAN_DETAILS_SUCCESS"
export const CHALLAN_DETAILS_FAIL = "CHALLAN_DETAILS_FAIL"
export const CREATE_CHALLAN_REQUEST = "CREATE_CHALLAN_REQUEST"
export const CREATE_CHALLAN_SUCCESS = "CREATE_CHALLAN_SUCCESS"
export const CREATE_CHALLAN_FAIL = "CREATE_CHALLAN_FAIL"
export const DELETE_CHALLAN_REQUEST = "DELETE_CHALLAN_REQUEST"
export const DELETE_CHALLAN_SUCCESS = "DELETE_CHALLAN_SUCCESS"
export const DELETE_CHALLAN_FAIL = "DELETE_CHALLAN_FAIL"
export const UPDATE_CHALLAN_REQUEST = "UPDATE_CHALLAN_REQUEST"
export const UPDATE_CHALLAN_SUCCESS = "UPDATE_CHALLAN_SUCCESS"
export const UPDATE_CHALLAN_FAIL = "UPDATE_CHALLAN_FAIL"
export const CHALLAN_PAGE_UPDATE = "CHALLAN_PAGE_UPDATE"
export const CLEAR_ERRORS = "CLEAR_ERRORS"