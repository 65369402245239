import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { getBills } from '../../redux/actions/billAction'
import moment from 'moment/moment'
import Loader from '../../utility/Loader'
import DeleteHandler from '../../components/BillingSystem/DeleteHandler'
import ChangeBillNumberHandler from '../../components/BillingSystem/ChangeBillNo'

const Bills = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {bills, loading} = useSelector(state=>state.bills)
    const [selectedBillId, setSelectedBillId] = useState()
    const [selectedBill, setSelectedBill] = useState()

    // Search States
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredBills, setFilteredBills] = useState(bills);


    useEffect(() => {
        if (bills.length===0) {
            dispatch(getBills())
          }
    }, [])

    useEffect(() => {
      if (bills.length>0) {
        const sortedFilteredBills = bills.sort((a, b) => b.taxNumber - a.taxNumber);
        setFilteredBills(sortedFilteredBills);
      }
    }, [bills])
    

    const duplicateHandler = (id) => {
      navigate(`/bill/new?duplicate=true&duplicateId=${id}`)
    }

    const convertHandler = (id) => {
      navigate(`/performaInvoice/new?convert=true&convertId=${id}`)
    }


    const handleDelete = (id) => {
      setSelectedBillId(id)
      document.getElementById("openDeleteHandlerModal").click()
    }

    const handleBillNumberChange = (item) => {
      setSelectedBill(item)
      document.getElementById("openChangeBillNumberHandler").click()
    }

  // Function to update the filtered bills based on the search term
  const updateFilteredBills = (term) => {
    const filtered = bills.filter((product) =>
      product.billTo.name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredBills(filtered);
  };

  // Event handler for input change
  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    updateFilteredBills(term);
  };

  const navigateToViewBill = (id) => (
    navigate(`/bill/print/${id}`)
  )

  return loading ? (<Loader/>) : (
    <section className='billing-system'>
      <div>
        <div className="bill-search">
          <h2>All Bills ({bills && bills.length})</h2>
          <div className="input-group my-3">
              <input type="text" className="form-control" placeholder="Search Bill" aria-label="Seach Bill" aria-describedby="basic-addon" value={searchTerm} onChange={handleInputChange}/>
              <span className="input-group-text" id="basic-addon"><i className="bi bi-search"></i></span>
            </div>
        </div>
        {filteredBills.map((item, index) => (
        <div key={index} className="d-flex flex-column flex-lg-row align-items-start justify-content-start align-items-lg-center justify-content-lg-between flex-wrap py-3 gap-3">
          <div className="flex-grow-1 w-full w-lg-auto">
            <div onClick={()=>navigateToViewBill(item._id)} className={`cursor-pointer shadow d-flex flex-wrap align-items-start justify-content-between text-white p-3 rounded-2 fw-regular ${index%2===0 ? 'bd-red-900' : 'bd-red-500'}`}>
              <div className='d-flex'>
                <div className="me-2">{String(item.taxNumber).padStart(3, '0')}</div>
                <div className="">{item.billTo.name}</div>
              </div>
              <div>
                {moment(item.createdAt).format('D MMM YY')}
              </div>
            </div>
          </div>
          <div>
            <div className="dropdown">
              <button className={"btn bd-gray-300 dropdown-toggle"} type="button" data-bs-toggle="dropdown" aria-expanded="false">Options</button>
              <ul className="dropdown-menu dropdown-menu-dark">
                <li><span className="dropdown-item cursor-pointer" onClick={()=>duplicateHandler(item._id)}>Duplicate bill</span></li>
                <li><span className="dropdown-item cursor-pointer" onClick={()=>convertHandler(item._id)}>Copy as PI</span></li>
                <li><Link className="dropdown-item" to={`/bill/print/${item._id}?print=true`}>Print</Link></li>
                <li><Link className="dropdown-item" to={`/bill/${item._id}`}>Edit</Link></li>
                <li><span className="dropdown-item cursor-pointer" onClick={()=>handleBillNumberChange(item)}>Change Bill No.</span></li>
                <li><span className="dropdown-item text-danger cursor-pointer" onClick={()=>handleDelete(item._id)}>Delete</span></li>
              </ul>
            </div>
            <DeleteHandler type={"bill"} id={selectedBillId} />
            <button className='d-none' data-bs-toggle="modal" data-bs-target="#deleteHandler" id='openDeleteHandlerModal'>Delete Handler</button>
            <ChangeBillNumberHandler bill={selectedBill}/>
            <button className='d-none' data-bs-toggle="modal" data-bs-target="#changeBillNumberHandler" id='openChangeBillNumberHandler'>Delete Handler</button>
            
          </div>
        </div>
        ))}

      </div>
    </section>
  )
}

export default Bills