import React from 'react'

const Loader = () => {
  return (
    <section className='position-fixed bg-body d-flex align-items-center justify-content-center' style={{height:"100vh", width:"100vw", zIndex:100}}>
        <div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
          </div>
    </section>
  )
}

export default Loader