import React, { useEffect, useState } from 'react'
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';

const SaveAndTotal = ({taxCategory, setTaxCategory, subTotalAmount, totalAmount, setTotalAmount, buttonLoading, handleSubmit, discount, setDiscount, taxPercentage, setTaxPercentage, error, stateCode, newItemDetails}) => {

  const [discountAmount, setDiscountAmount] = useState(subTotalAmount * discount/100)
  const [taxableAmount, setTaxableAmount] = useState(subTotalAmount - (subTotalAmount * discount/100))

  useEffect(() => {
    const discountAmt = subTotalAmount * discount/100
    const taxableAmt = subTotalAmount - (subTotalAmount * discount/100)
    setDiscountAmount(discountAmt)
    setTaxableAmount(taxableAmt)
    setTotalAmount(taxableAmt + taxableAmt *taxPercentage/100)
  }, [subTotalAmount, discount, taxPercentage, setTotalAmount])

  useEffect(() => {
    if (stateCode) {
      if (stateCode.toString() === '07' || stateCode.toString() === '7') {
        setTaxCategory("DEL")
      } else {
        setTaxCategory("IN")
      }
    }
  }, [setTaxCategory, stateCode])
  

  return (
    <section className='py-5'>
        <div className="row">
          <div className="col-12 col-lg-4  d-flex justify-content-center align-items-end flex-column order-2 order-lg-2">
            <p className='flex-between mb-1'><span>Tax Percentage <input type="number" className='discount-input bg-primary text-white' value={taxPercentage} onChange={(e)=>setTaxPercentage(e.target.value)} /> (%) </span></p>
            <FormControlLabel control={<Radio />} className="tax-category-label" checked={taxCategory === "IN"} value="IN" onChange={(e)=>{setTaxCategory("IN")}} label={`India - IGST (${taxPercentage}%)`} labelPlacement="start" />
            <FormControlLabel control={<Radio />} className="tax-category-label" checked={taxCategory === "DEL"} value="DEL" onChange={(e)=>{setTaxCategory("DEL")}} label={`Delhi - CGST (${taxPercentage/2}%) + SGST (${taxPercentage/2}%)`} labelPlacement="start" />
          </div>
          <div className="col-12 col-lg-4 order-2 order-lg-3">
            <div className="bg-primary p-3">
              <p className='flex-between mb-1 text-white'><span>Sub-Total</span><span className='fw-semibold'>Rs. {parseFloat(subTotalAmount).toFixed(2)}</span></p>
              <p className='flex-between mb-1 text-white'><span>Discount <input type="number" className='discount-input' value={discount} onChange={(e)=>setDiscount(e.target.value)} /> (%) </span><span className='fw-semibold'>Rs. {parseFloat(discountAmount).toFixed(2)}</span></p>
              <p className='flex-between mb-1 text-white'><span>Taxable Amt</span><span className='fw-semibold'>Rs. {parseFloat(taxableAmount).toFixed(2)}</span></p>
              {taxCategory === "IN" ? <p className='flex-between mb-1 text-white'><span>IGST ({taxPercentage}%)</span><span className='fw-semibold'>Rs. {parseFloat((subTotalAmount - discountAmount) *taxPercentage/100).toFixed(2)}</span></p> : <>
                    <p className='flex-between mb-1 text-white'><span>CGST ({taxPercentage/2}%)</span><span className='fw-semibold'>Rs. {parseFloat((subTotalAmount - discountAmount) *(taxPercentage/2)/100).toFixed(2)}</span></p>
                    <p className='flex-between mb-0 text-white'><span>SGST ({taxPercentage/2}%)</span><span className='fw-semibold'>Rs. {parseFloat((subTotalAmount - discountAmount) *(taxPercentage/2)/100).toFixed(2)}</span></p>
              </>}
            </div>
            <div className="bg-black p-3">
              <h4 className='fw-bold flex-between mb-0 text-white'><span>Grand Total</span><span>Rs. {Math.round(totalAmount)}</span></h4>
            </div>
          </div>
          <div className="col-12 col-lg-4 d-flex flex-wrap justify-content-start align-items-end order-3 order-lg-1 my-3 my-lg-0">
            <LoadingButton loadingPosition="start" disabled={newItemDetails.name} loading={buttonLoading} onClick={()=>handleSubmit("saveAndPrint")} variant="contained" color='primary' startIcon={<PrintIcon />} className='me-2'>Save & Print</LoadingButton>
            <LoadingButton loadingPosition="start" disabled={newItemDetails.name} loading={buttonLoading} onClick={()=>handleSubmit("save")} variant="contained" color='success' startIcon={<SaveIcon />}>Save</LoadingButton>
          </div>
        </div>
        {error && <p className="text-danger mt-5">{error}</p>}
        {newItemDetails.name && <p className="text-danger mt-5">Please Save Item before proceding further</p>}
    </section>
  )
}

export default SaveAndTotal