import {
    ALL_CHALLAN_FAIL,
    ALL_CHALLAN_REQUEST,
    ALL_CHALLAN_SUCCESS, 
    CHALLAN_DETAILS_REQUEST,
    CHALLAN_DETAILS_SUCCESS,
    CHALLAN_DETAILS_FAIL,
    CREATE_CHALLAN_FAIL,
    CREATE_CHALLAN_REQUEST,
    CREATE_CHALLAN_SUCCESS,
    DELETE_CHALLAN_FAIL,
    DELETE_CHALLAN_REQUEST,
    DELETE_CHALLAN_SUCCESS,
    UPDATE_CHALLAN_FAIL,
    UPDATE_CHALLAN_REQUEST,
    UPDATE_CHALLAN_SUCCESS,
    CHALLAN_PAGE_UPDATE,
    CLEAR_ERRORS
} from "../constants/challanConstant"

const initialState = {
    challans: [],
    challan: {},
    challansCount: 0, 
    length: 0,
    challanPage: 1,
    challansPerPage : 200
}

export const challanReducer = (state = initialState, action) => {

    switch (action.type) {
        case ALL_CHALLAN_REQUEST:
            
            return {
                ...state,
                loading: true,
            }
        
        case ALL_CHALLAN_SUCCESS:
        
            return {
                ...state,
                loading: false,
                challans: action.payload.allChallans,
                challansCount: action.payload.totalChallans
            }

        case ALL_CHALLAN_FAIL:
    
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        
        case CHALLAN_DETAILS_REQUEST:
    
            return {
                ...state,
                loading: true,
            }
        
        case CHALLAN_DETAILS_SUCCESS:
        
            return {
                ...state,
                loading: false,
                challan: action.payload,
            }

        case CHALLAN_DETAILS_FAIL:
    
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CREATE_CHALLAN_REQUEST:
        
            return {
                ...state,
            }
        
        case CREATE_CHALLAN_SUCCESS:
        
            return {
                ...state,
                challans: [action.payload.challan, ...state.challans],
                message: action.payload.message,
                loading: false,
                success: true
            }

        case CREATE_CHALLAN_FAIL:
    
            return {
                ...state,
                error: action.payload
            }
        
        case DELETE_CHALLAN_REQUEST:
    
            return {
                ...state,
            }
        
        case DELETE_CHALLAN_SUCCESS:
        
            return {
                // ...state,
                // success: true,
                ...state,
                challans: state.challans.filter((i)=>i._id !== action.payload),
                success: true,
                message: "Challan Deleted Successfully"
            }

        case DELETE_CHALLAN_FAIL:
    
            return {
                ...state,
                error: action.payload
            }
        
        case UPDATE_CHALLAN_REQUEST:
    
            return {
                ...state,
            }
        
        case UPDATE_CHALLAN_SUCCESS:
        
            // return {
            //     ...state,
            //     challan: action.payload.challan,
            //     success: true,
            //     message: "Challan updated Successfully"
            // }
        
            const item = action.payload.challan

            const isItemExists = state.challans.find((i) => i._id === item._id)

            if (isItemExists) {
                return {
                    ...state,
                    success: true,
                    message: "Challan updated Successfully",
                    loading: false,
                    challans: state.challans.map((i)=>
                        i._id === isItemExists._id ? item : i
                    )
                }
            }
            

        case UPDATE_CHALLAN_FAIL:
    
            return {
                ...state,
                error: action.payload
            }

        case CHALLAN_PAGE_UPDATE:

        return {
            ...state,
            challanPage: action.payload
        }

        case CLEAR_ERRORS:
    
            return {
                ...state,
                error: null
            }
    
        default:
            return state
    }

}
