import React from 'react'
import Challans from './challans/Challans'

const Homepage = () => {

  return (
    <Challans/>
  )
}

export default Homepage