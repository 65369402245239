import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getChallanDetails, updateChallan } from '../../redux/actions/challanActions'
import moment from 'moment'
import 'moment/locale/en-gb';
import Loading from '../../components/utility/Loading'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers'

import ChallanDetailsHandler from '../../components/Challans/ChallanDetailsHandler'
import ItemsHandler from '../../components/Challans/ItemsHandler'
import { getPriceLists, updatePriceList } from '../../redux/actions/priceListAction'
import { addItemsToCategory, newItemsIdentifier } from '../../utility/products'
import Loader from '../../utility/Loader'


const EditChallan = () => {
    
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {priceLists} = useSelector(state=>state.priceLists)
    const {challans} = useSelector(state=>state.challans)

    const [error, setError] = useState()
    const [loading, setLoading] = useState(true)
    const [buttonLoading, setButtonLoading] = useState(false)

    
    const [date, setDate] = useState(Date.now())
    const [name, setName] = useState()
    const [address, setAddress] = useState()
    const [transporterName, setTransporterName] = useState()
    const [marka, setMarka] = useState()
    const [note, setNote] = useState("")
    const [nag, setNag] = useState()
    const [totalAmount, setTotalAmount] = useState()
    const [selectedPriceList, setSelectedPriceList] = useState()
    const [items, setItems] = useState([])

    const [newItemDetails, setNewItemDetails] = useState({
    name: '',
    quantity: '',
    price: '',
    totalAmount: 0,
    randomId: 0
    })

    
    // useEffect(() => {
    //   if (priceLists.length===0) {
    //     dispatch(getPriceLists())
    //   }
    // }, [])

    // useEffect(() => {
    //   if (priceLists) {
    //     getChallan()
    //   }
    // }, [priceLists])

    useEffect(() => {
      getChallan()
    }, [])
  
    const getChallan = () => {
      const isChallanExist = challans.find((i) => i._id === id)
      if (isChallanExist) {
          setChallanDetails(isChallanExist)
      } else {
          dispatch(getChallanDetails(id)).then((res) => {
              if (res.success) {
                setChallanDetails(res.challan)
              }
          })
    }

  }

  const setChallanDetails = (challan) => {
    setDate(challan.createdAt)
    setName(challan.name)
    setAddress(challan.address)
    setTransporterName(challan.transporterName)
    setMarka(challan.marka)
    setNag(challan.nag)
    setTotalAmount(challan.totalAmount)
    setNote(challan.note)
    setItems(challan.items)
    setSelectedPriceList(priceLists.find((i) => i.name === challan.priceList))
    setLoading(false)
  }
      
  useEffect(() => {
    if (items.length>0) {
        let amount = 0
        for (let i = 0; i < items.length; i++) {
          const itemTotalAmount = items[i].totalAmount;
          amount = amount + itemTotalAmount
        }
        setTotalAmount(amount)
    }
  }, [items])

    const challanHandler = (action) => {
      setButtonLoading(true)
      newItemsEditor(action)
    }

    const newItemsEditor = (action) => {
      const newItems = newItemsIdentifier(selectedPriceList, items)
      let newPriceList = selectedPriceList
      if (newItems.length > 0) {
        newPriceList = addItemsToCategory(newItems, 'miscellaneous', selectedPriceList);
        dispatch(updatePriceList(newPriceList._id, newPriceList))
        .then(res => {
          if (res.success) {
            createChallanHandler(action)
          } else {
            setError(res.message)
          }
        })
      } else {
        createChallanHandler(action)
      }
    }

    const createChallanHandler = (action) => {
      const data = {
        name: name.trim(),
        totalAmount: totalAmount,
        items: items,
        address: address,
        marka: marka,
        nag: nag,
        note: note,
        transporterName: transporterName,
        priceList: selectedPriceList.name,
        createdAt: JSON.stringify(date).substring(1, JSON.stringify(date).length -1)
      }
      dispatch(updateChallan(id, data))
        .then(res => {
          if (res.success) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            action === "saveAndPrint" ? navigate(`/challan/print/${res.challan._id}?print=true`) : navigate('/')
          } else {
            setError(res.message)
          }
          setButtonLoading(false)
        })
    }


  return loading ? (
    <Loader />) : (
          <section className='pb-5'>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <h2 className='fw-bold text-primary'>Edit Challan</h2>
            <div className='position-relative d-flex'>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-gb"}>
                  <MobileDatePicker
                      value={date}
                      onChange={(newValue) => {
                          setDate(newValue);
                      }}
                      renderInput={({ inputRef, inputProps, InputProps }) => (
                          <>
                            <h2 className='text-primary text-underline fw-bold cursor-pointer' ref={inputRef} {...inputProps}>{moment(date).format('D MMM YYYY')}</h2>
                            {InputProps?.endAdornment}
                          </>
                      )}
                  />
                </LocalizationProvider>
              </div>
          </div>
          <ChallanDetailsHandler name={name} setName={setName} address={address} setAddress={setAddress} transporterName={transporterName} setTransporterName={setTransporterName} marka={marka} setMarka={setMarka} nag={nag} setNag={setNag} note={note} setNote={setNote} selectedPriceList={selectedPriceList} setSelectedPriceList={setSelectedPriceList} >
              <ItemsHandler items={items} setItems={setItems} totalAmount={totalAmount} setTotalAmount={setTotalAmount} newItemDetails={newItemDetails} setNewItemDetails={setNewItemDetails} selectedPriceList={selectedPriceList} />
          </ChallanDetailsHandler>
          <div className="row">
            <div className="col-lg-2 col-sm-6">
              <button className='w-100 shadow btn btn-success mt-4 me-3' onClick={()=>challanHandler("saveAndPrint")} disabled={newItemDetails.name}>{buttonLoading ? <div className="spinner-border button-spinner" role="status"><span className="visually-hidden">Loading...</span></div> : "Save & Print"}</button>
            </div>
            <div className="col-lg-2 col-sm-6">
              <button className='w-100 shadow btn btn-primary mt-4' onClick={()=>challanHandler("save")} disabled={newItemDetails.name}>{buttonLoading ? <div className="spinner-border button-spinner" role="status"><span className="visually-hidden">Loading...</span></div> : "Save"}</button>
            </div>
          </div>
            {newItemDetails.name && <p className="text-danger">Please Save Item before proceding further</p>}
            {error && <p className="text-danger">{error}</p>}
      </section>
  )
}

export default EditChallan