import React from 'react'

const LastPage = () => {
  return (
    <div className="last-page" style={{backgroundImage: "url(/assets/img/priceList/6.svg)"}}>

    </div>
  )
}

export default LastPage