import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { getPriceListDetails } from '../../redux/actions/priceListAction'
import Loader from '../../utility/Loader'
import PrintPriceList from '../../components/PriceList/PrintPriceList'

const ViewPriceList = () => {

    const { id } = useParams()
    const dispatch = useDispatch()
    const {priceLists} = useSelector(state=>state.priceLists)

    const [priceList, setPriceList] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const isPriceListExist = priceLists.find((i) => i._id === id)
        if (isPriceListExist) {
            setPriceList(isPriceListExist)
            setLoading(false)
        } else {
            dispatch(getPriceListDetails(id)).then((res) => {
                if (res.success) {
                    setPriceList(res.priceList)
                    setLoading(false)
                }
            })
        }
    }, [])

    const handlePrint = () => {
        window.print();
      };

  return loading ? <Loader/> : (
    <div>
        <nav className='navbar navbar-light position-lg-sticky top-lg-0 overlap-10 flex-none bg-primary border-bottom px-0 py-3 d-print-none'>
            <div className="container-fluid d-flex align-items-center justify-content-between">
                <Link to='/' className='text-white h6 fw-bold'>Omtech Auto</Link>
                <div><span onClick={handlePrint} className="p-2 px-4 bg-new-success rounded text-white text-l fw-semibold cursor-pointer"><i className="bi bi-printer"></i> Print Price List</span></div>

            </div>
        </nav>
        {priceList && 
            <div className='container-fluid py-5 d-print-none'>
                <div className="d-flex align-items-center justify-content-between">
                    <h2 className='text-black'>{priceList.name}</h2>
                </div>
                {priceList.details.map((category, index)=>(
                    <div className='mt-5 d-flex align-items-center flex-column text-uppercase' key={index}>
                        <h4 className='bg-new-danger py-4 text-white text-center w-100'>{category.categoryName}</h4>
                        {category.categoryItems && category.categoryItems.map((item, indexItems)=>(
                            <div className={`row w-100 price-list-items ${indexItems%2===0 ? 'bg-gray-200' : 'bg-gray-300'}`} key={indexItems}>
                                <div className="col-8"><p>{item.name}</p></div>
                                <div className="col-2"><p>{item.position}</p></div>
                                <div className="col-2"><p>{item.price}</p></div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        }
         {priceList && <PrintPriceList priceList={priceList} />}
    </div>
  ) 
}

export default ViewPriceList