import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import Modal from "../utility/Modal"
import Toaster from "../utility/Toaster"
import { updateBill } from '../../redux/actions/billAction'
import TextField from '@mui/material/TextField';

const ChangeBillNumberHandler = ({bill}) => {

    const dispatch = useDispatch()
    const [buttonLoading, setButtonLoading] = useState(false)
  
    // toaster states
    const [toaster, setToaster] = useState(false)
    const [toasterMessage, setToasterMessage] = useState("")
    const [toasterType, setToasterType] = useState("")

    const [taxNumber, setTaxNumber] = useState(bill?.taxNumber)

    useEffect(() => {
      if (bill && bill.taxNumber) {
        setTaxNumber(bill.taxNumber)
      }
    }, [bill])
    

    const handelSubmit = (e) => {
      e.preventDefault();
      setButtonLoading(true)
      const data = {
        taxNumber: taxNumber
      }
      dispatch(updateBill(bill._id, data))
      .then(res => {
        setButtonLoading(false)
        if (!res.success) {
            handleToaster(res.message, "error")
        } else {
            handleToaster("Bill Number Changed Successfully", "success")
        }
        closeModal()
      })
    }
  
    const closeModal = () => {
      document.getElementById("closeChangeBillNumberHandlerModal").click()
    }
  
    const handleToaster = (message, icon) => {
      setToaster(true)
      setToasterMessage(message)
      setToasterType(icon)
    }
    

  return (
    <div>
        <Modal targetName={"changeBillNumberHandler"}>
            <div className='p-lg-5 p-1 text-center'>
                <h1>Change Bill Number</h1>
                <TextField label="Bill No." variant="outlined" type="number" className="w-100 mt-4" value={taxNumber} onChange={(e)=>setTaxNumber(e.target.value)}/>
                <div className="row flex-center mt-5">
                    <div className="col-12 col-lg-6">
                        <button className="btn btn-primary shadow w-100 fs-4 flex-center" onClick={closeModal}>Cancel</button>
                    </div>
                    <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                        <button className="btn btn-danger shadow w-100 fs-4 flex-center" onClick={handelSubmit}>{buttonLoading ? <div className="spinner-border fs-6 button-spinner flex-center" role="status"><span className="visually-hidden">Loading...</span></div> : "Save"}</button>
                    </div>
                </div>
            </div>
            <button className='d-none' data-bs-dismiss="modal" id="closeChangeBillNumberHandlerModal">Close modal</button>
        </Modal>
        <Toaster text={toasterMessage} toasterType={toasterType} toaster={toaster} setToaster={setToaster} />
    </div>
  )
}

export default ChangeBillNumberHandler