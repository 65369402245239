import {
    ALL_BILL_FAIL,
    ALL_BILL_REQUEST,
    ALL_BILL_SUCCESS, 
    BILL_DETAILS_REQUEST,
    BILL_DETAILS_SUCCESS,
    BILL_DETAILS_FAIL,
    CREATE_BILL_FAIL,
    CREATE_BILL_REQUEST,
    CREATE_BILL_SUCCESS,
    DELETE_BILL_FAIL,
    DELETE_BILL_REQUEST,
    DELETE_BILL_SUCCESS,
    UPDATE_BILL_FAIL,
    UPDATE_BILL_REQUEST,
    UPDATE_BILL_SUCCESS,
    CLEAR_ERRORS
} from "../constants/billConstant"

const initialState = {
    bills: [],
    bill: {},
    billsCount: 0, 
    length: 0,
}

export const billReducer = (state = initialState, action) => {

    switch (action.type) {
        case ALL_BILL_REQUEST:
            
            return {
                ...state,
                loading: true,
            }
        
        case ALL_BILL_SUCCESS:
        
            return {
                ...state,
                loading: false,
                bills: action.payload.allBills,
                billsCount: action.payload.allBills.length
            }

        case ALL_BILL_FAIL:
    
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        
        case BILL_DETAILS_REQUEST:
    
            return {
                ...state,
                loading: true,
            }
        
        case BILL_DETAILS_SUCCESS:
        
            return {
                ...state,
                loading: false,
                bill: action.payload,
            }

        case BILL_DETAILS_FAIL:
    
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CREATE_BILL_REQUEST:
        
            return {
                ...state,
            }
        
        case CREATE_BILL_SUCCESS:
        
            return {
                ...state,
                bills: [action.payload.bill, ...state.bills],
                message: action.payload.message,
                loading: false,
                success: true
            }

        case CREATE_BILL_FAIL:
    
            return {
                ...state,
                error: action.payload
            }
        
        case DELETE_BILL_REQUEST:
    
            return {
                ...state,
            }
        
        case DELETE_BILL_SUCCESS:
        
            return {
                // ...state,
                // success: true,
                ...state,
                bills: state.bills.filter((i)=>i._id !== action.payload),
                success: true,
                message: "Bill Deleted Successfully"
            }

        case DELETE_BILL_FAIL:
    
            return {
                ...state,
                error: action.payload
            }
        
        case UPDATE_BILL_REQUEST:
    
            return {
                ...state,
            }
        
        case UPDATE_BILL_SUCCESS:
        
            // return {
            //     ...state,
            //     bill: action.payload.bill,
            //     success: true,
            //     message: "Bill updated Successfully"
            // }
        
            const item = action.payload.bill

            const isItemExists = state.bills.find((i) => i._id === item._id)

            if (isItemExists) {
                return {
                    ...state,
                    success: true,
                    message: "Bill updated Successfully",
                    loading: false,
                    bills: state.bills.map((i)=>
                        i._id === isItemExists._id ? item : i
                    )
                }
            }
            

        case UPDATE_BILL_FAIL:
    
            return {
                ...state,
                error: action.payload
            }

        case CLEAR_ERRORS:
    
            return {
                ...state,
                error: null
            }
    
        default:
            return state
    }

}
