import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Sidebar = () => {

    const navigate = useNavigate()
  
    const handleLogout = () => {
      localStorage.clear()
      navigate('/login')
    }

    const links = [
        { name: "Challans", href: "/" },
        { name: "Price List", href: "/priceList" },
        { name: "Bills", href: "/bills" },
        { name: "Performa Invoices", href: "/performaInvoices" },
        { name: "Analytics", href: "/analytics" },
    ]

    const handleLink = (link) => {
        document.getElementById("navCloseBtn").click()
        navigate(link)
    }

  return (
        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header pb-0">
                <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Navigation</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" id='navCloseBtn'></button>
            </div>
            <div className="offcanvas-body d-flex flex-column justify-content-between">
                <ul className="navbar-nav pe-3">
                    <li className="nav-item d-flex flex-column">
                        {links.map((item, index)=>(
                            <span key={index} className="nav-link text-dark cursor-pointer" aria-current="page" onClick={()=>{handleLink(item.href)}}>{item.name}</span>
                        ))}
                        <span onClick={handleLogout} className="nav-link cursor-pointer text-danger" aria-current="page" to="/">Log Out</span>
                    </li>
                </ul>
            </div>
        </div>
  )
}

export default Sidebar