export const TOP_ITEMS_SOLD_REQUEST = "TOP_ITEMS_SOLD_REQUEST"
export const TOP_ITEMS_SOLD_SUCCESS = "TOP_ITEMS_SOLD_SUCCESS"
export const TOP_ITEMS_SOLD_FAIL = "TOP_ITEMS_SOLD_FAIL"
export const TOTAL_SALES_REQUEST = "TOTAL_SALES_REQUEST"
export const TOTAL_SALES_SUCCESS = "TOTAL_SALES_SUCCESS"
export const TOTAL_SALES_FAIL = "TOTAL_SALES_FAIL"
export const TOP_CUSTOMERS_REQUEST = "TOP_CUSTOMERS_REQUEST"
export const TOP_CUSTOMERS_SUCCESS = "TOP_CUSTOMERS_SUCCESS"
export const TOP_CUSTOMERS_FAIL = "TOP_CUSTOMERS_FAIL"
export const FILTER_CHALLANS_REQUEST = "FILTER_CHALLANS_REQUEST"
export const FILTER_CHALLANS_SUCCESS = "FILTER_CHALLANS_SUCCESS"
export const FILTER_CHALLANS_FAIL = "FILTER_CHALLANS_FAIL"
export const TOTAL_TAX_SALES_REQUEST = "TOTAL_TAX_SALES_REQUEST"
export const TOTAL_TAX_SALES_SUCCESS = "TOTAL_TAX_SALES_SUCCESS"
export const TOTAL_TAX_SALES_FAIL = "TOTAL_TAX_SALES_FAIL"
export const GET_CUSTOMERS_REQUEST = "GET_CUSTOMERS_REQUEST"
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL"
export const CLEAR_ERRORS = "CLEAR_ERRORS"